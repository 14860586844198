import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
`


export const ReceivingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
`

export const ReceivingTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
`

export const ReceivingTypeCheckboxContainer = styled.div`
  //display: flex;
  //gap: .5rem;
  //align-items: center;
`