import styled from "styled-components";
import {ReactComponent as StarSVG} from "../icons/star.svg";

export const Container = styled.div`
  display: flex;
  gap: .5em;
`

export const Star = styled(StarSVG)`
  height: 1em;
  width: 1em;
`