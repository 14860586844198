import React, {useContext, useEffect} from "react";
import {useLocation} from "react-router-dom";
import DataContext from "./DataProvider";
import {useEffectOnce} from "react-use";

export const ScrollToTop: React.FC<{ el: HTMLDivElement }> = ({el}) => {
    const {pathname} = useLocation();
    const data = useContext(DataContext)

    useEffectOnce(() => {
        if (data.user.city.isWork && data.user.city.status === 1) {
            (window as any).ym(93650254, 'reachGoal', 'storeLoaded')
        }
    })

    useEffect(() => {
        el.scrollTo(0, 0);
    }, [el, pathname]);

    return null;
}

export default ScrollToTop