import * as styles from '../styles/legalInfo'
import React, {useContext, useMemo} from "react";
import DataContext from "./DataProvider";

interface Document {
    title: string,
    url: string
}

const documents: Document[] = [
    {
        title: 'Пользовательское соглашение об условиях доставки',
        url: 'agreement'
    },
    {
        title: 'Политика конфиденциальности',
        url: 'privacy-policy'
    },
    {
        title: 'Состав и калорийность',
        url: 'compound.pdf'
    },
    {
        title: 'Правила бонусной системы',
        url: 'bonus-regulations'
    }
]

const franchise: Document = {
    title: 'Франшиза',
    url: 'https://franchise.roliksushi.ru/'
}

export const LegalInfo: React.FC = () => {

    const data = useContext(DataContext)
    const urlBase = useMemo(() => `https://${data.user.city.domain}.roliksushi.ru`, [data.user.city.domain])

    return (
        <styles.Container>
            <styles.LegalText>
                © {new Date().getFullYear()} Все права защищены
            </styles.LegalText>
            <styles.DocumentsContainer>
                {documents.map(d =>
                    <styles.DocumentLink target='_blank' href={`${urlBase}/${d.url}`}>
                        {d.title}
                    </styles.DocumentLink>
                )}
                <styles.DocumentLink target='_blank' href={franchise.url}>
                    {franchise.title}
                </styles.DocumentLink>
            </styles.DocumentsContainer>
        </styles.Container>
    )
}

export default LegalInfo