import React from "react";
import * as styles from "../../styles/checkout/card"

interface Props {
    title?: string,
    children: React.ReactNode
}

const Card: React.FC<Props> = ({title, children}) => {

    return (
        <styles.Container>
            {title &&
                <styles.HeaderContainer>
                    <styles.Header>
                        {title}
                    </styles.Header>
                </styles.HeaderContainer>}
            <styles.Content>
                {children}
            </styles.Content>
        </styles.Container>
    )
}

export default Card