import React, {useContext} from "react";
import {MainButton} from "@vkruglikov/react-telegram-web-app";
import DataContext from "./DataProvider";
import {useNavigate} from "react-router-dom";

const convertProductNameForm = (count: number): string => {
    const lastDigit = count % 10
    if ((count < 11 || count > 20) && lastDigit === 1) {
        return "товар"
    }

    if ((count < 11 || count > 20) && lastDigit < 5) {
        return "товара"
    }

    return "товаров"
}

const Cart: React.FC = () => {

    const data = useContext(DataContext)

    const navigate = useNavigate()

    return (
        <>
            <MainButton
                onClick={() => navigate('/cart')}
                color="#C50000"
                textColor="#FFFFFF"
                text={data.cartData.count
                    ?
                    `${data.cartData.count} ${convertProductNameForm(data.cartData.count)}. ${data.cartData.discountCost || data.cartData.cost} ₽`
                    :
                    'Корзина'}/>
        </>

    )
}

export default Cart