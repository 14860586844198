import React, {useCallback, useContext, useMemo, useState} from "react";
import * as styles from "../../styles/cart"
import DataContext from "../DataProvider";
import CartItemButton from "../CartItemButton";
import {Product} from "../../../../api/dto/product.dto";
import {BaseCartItem, CartItem, FreeCartItem} from "../../../../api/dto/cart.dto";
import {useEffectOnce} from "react-use";
import {ClipLoader} from "react-spinners";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {useNavigate} from "react-router-dom";
import SelectedProduct from "../SelectedProduct";

const spicesCategoryUrl = "sousy_i_spetsii"

const isCartItem = (i: CartItem | Product): i is CartItem => 'count' in i

const isFreeCartItem = (item: CartItem): item is FreeCartItem => {
    return !item.hasOwnProperty("cost") && !item.hasOwnProperty("oldCost")
}


const isBaseCartItem = (item: CartItem): item is BaseCartItem => {
    return item.hasOwnProperty("cost") && item.hasOwnProperty("oldCost")
}

const CartPage: React.FC = () => {

    const data = useContext(DataContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [coupon, setCoupon] = useState<string>(data.cartData.coupon || "")
    const [showAll, setShowAll] = useState(false)
    const [species, setSpecies] = useState<Product[]>([])
    const items = useMemo(() => {
        return species.length ? data.cartData.items.filter(i => !species.find(p => p.id === i.id)) : []
    }, [data.cartData.items, species])
    const [selectedProduct, setSelectedProduct] = useState<Product | null | CartItem>(null)

    const handleProductSelect = useCallback((p: Product | CartItem) => {
        setSelectedProduct(p)
    }, [])


    useEffectOnce(() => {
        (window as any).ym(93650254, 'hit', '/cart')
        Promise.all([
            data.updateCartItem(null),
            data.getProducts(spicesCategoryUrl).then(res => setSpecies(res))
        ])
            .finally(() => setLoading(false))
    })

    const applyCoupon = useCallback(() => {
        setLoading(true)
        data.updateCartCoupon(coupon).finally(() => setLoading(false))
    }, [data, coupon])

    const clearCoupon = useCallback(() => {
        setLoading(true)
        setCoupon("")
        data.updateCartCoupon(undefined).finally(() => setLoading(false))
    }, [data])

    const onUpdateStarted = useCallback(() => {
        setLoading(true)
    }, [])

    const onUpdateEnded = useCallback(() => {
        setLoading(false)
    }, [])


    const renderItems = useCallback((items: (Product | CartItem)[], selectable = false) => {
        return (
            items.map(item =>
                <styles.CartItemContainer key={item.id}>
                    <styles.CartImageContainer>
                        <styles.CartImage url={item.image}/>
                    </styles.CartImageContainer>
                    <styles.CartItemDescriptionContainer>
                        <styles.CartItemTitle
                            {...(selectable && {onClick: () => handleProductSelect(item)})}>
                            {item.title}
                        </styles.CartItemTitle>
                        <styles.CartItemPrice>
                            {isCartItem(item)
                                ?
                                isBaseCartItem(item)
                                    ?
                                    <>{item.count} шт. {item.cost * item.count}₽</>
                                    :
                                    <>Бесплатно</>
                                :
                                <>{item.newPrice}₽</>
                            }
                        </styles.CartItemPrice>
                    </styles.CartItemDescriptionContainer>
                    <styles.CartItemCountContainer>
                        {isCartItem(item) && isFreeCartItem(item)
                            ?
                            <>{item.count} шт.</>
                            :
                            <CartItemButton item={data.cartItemsData[item.id] || item} onlyCount
                                            onStartChanging={onUpdateStarted} onChange={onUpdateEnded}/>
                        }


                    </styles.CartItemCountContainer>
                </styles.CartItemContainer>)
        )
    }, [data.cartItemsData, onUpdateStarted, onUpdateEnded, handleProductSelect])

    const disabled = useMemo(() => !data.cartData.count || loading, [data.cartData.count, loading, data.user.city.isWork])

    const navigate = useNavigate()

    return (<styles.CartContainer>
        <MainButton
            disable={disabled}
            color={disabled ? "#797979" : "#C50000"}
            textColor={disabled ? "#e3e3e3" : "#FFFFFF"}
            onClick={() => navigate('/checkout')}
            text={data.cartData.count ? `Сделать заказ. (${data.cartData.discountCost || data.cartData.cost} ₽)` : 'В корзине пусто'}
        />
        <BackButton onClick={() => navigate(-1)}/>
        {data.cartData.count
            ?
            <>
                {selectedProduct &&
                    <SelectedProduct
                        item={selectedProduct}
                        onClose={() => setSelectedProduct(null)}
                        onStartChanging={onUpdateStarted}
                        onChange={onUpdateEnded}
                    />
                }
                {loading &&
                    <styles.LoadingModal>
                        <ClipLoader/>
                    </styles.LoadingModal>
                }
                <styles.CartCardContainer>
                    <styles.CardHeaderContainer>
                        <styles.CartTitle>
                            Корзина
                        </styles.CartTitle>
                        <styles.ClearCartIcon onClick={_ => data.clearCart()}/>
                    </styles.CardHeaderContainer>
                    <styles.CartItemsContainer>
                        {renderItems(items, true)}
                    </styles.CartItemsContainer>
                    <styles.CartPriceContainer>
                        <styles.PriceLabel>
                            Сумма корзины:
                        </styles.PriceLabel>
                        <styles.PriceContainer>
                            <styles.Price>
                                {data.cartData.discountCost || data.cartData.cost}₽
                            </styles.Price>
                            {data.cartData.discountCost &&
                                <styles.OldPrice>
                                    {data.cartData.cost}₽
                                </styles.OldPrice>
                            }
                        </styles.PriceContainer>
                    </styles.CartPriceContainer>
                </styles.CartCardContainer>
                <styles.CartCardContainer>
                    <styles.PromoCodeContainer>
                        <styles.PromoCodeTitleContainer>
                            <styles.PromoCodeTitle>Введите</styles.PromoCodeTitle>
                            <styles.PromoCodeTitleAlt> промокод</styles.PromoCodeTitleAlt>
                            <styles.PromoCodeTitle> для скидки:</styles.PromoCodeTitle>
                        </styles.PromoCodeTitleContainer>
                        <styles.PromoCodeInputContainer>
                            <styles.InputContainer>
                                <styles.PromoCodeInput placeholder="Промокод" value={coupon}
                                                       onChange={(e) => setCoupon(e.target.value)}/>
                                {coupon && <styles.ClearCouponIcon onClick={() => clearCoupon()}/>}
                            </styles.InputContainer>
                            <styles.PromoCodeButton>
                                <styles.ButtonText onClick={() => applyCoupon()}>
                                    Применить
                                </styles.ButtonText>
                            </styles.PromoCodeButton>
                        </styles.PromoCodeInputContainer>
                        {!!data.cartData.couponDescription &&
                            <styles.PromoCodeDescription valid={data.cartData.couponValid}>
                                {data.cartData.couponDescription}
                            </styles.PromoCodeDescription>}
                        {!!data.cartData.promotional.length && (
                            <>
                                <styles.CardHeaderContainer>
                                    <styles.CartTitle>
                                        Ваши подарки
                                    </styles.CartTitle>
                                </styles.CardHeaderContainer>
                                <styles.CartItemsContainer>
                                    {renderItems(data.cartData.promotional)}
                                </styles.CartItemsContainer>
                            </>
                        )
                        }
                    </styles.PromoCodeContainer>
                </styles.CartCardContainer>


                <styles.CartCardContainer>
                    <styles.CardHeaderContainer>
                        <styles.CartTitle>
                            Специи
                        </styles.CartTitle>
                    </styles.CardHeaderContainer>
                    <styles.CartItemsContainer>

                        {data.cartData.specials.every(i => i.count > 0) && renderItems(data.cartData.specials)}
                        {renderItems(showAll ? species : species.slice(0, 4))}
                    </styles.CartItemsContainer>
                    <styles.ShowAllContainer>
                        <styles.ShowAllButton onClick={() => setShowAll(!showAll)}>
                            {showAll ? <styles.ArrowUp/> : <styles.ArrowDown/>}
                            <styles.ShowAllText>
                                {showAll ? 'Скрыть' : 'Показать всё'}
                            </styles.ShowAllText>
                        </styles.ShowAllButton>
                    </styles.ShowAllContainer>
                </styles.CartCardContainer>
            </>
            :
            <styles.EmptyCartTitle>
                В корзине нет товаров
            </styles.EmptyCartTitle>
        }

    </styles.CartContainer>)
}

export default CartPage