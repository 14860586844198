import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import DataContext, {CheckoutData, Data} from "../DataProvider";
import * as styles from "../../styles/checkout";
import Stepper from "../checkout/Stepper";
import {BackButton, useShowPopup} from "@vkruglikov/react-telegram-web-app";
import {useNavigate} from "react-router-dom";
import ReceivingTypeForm from "../checkout/forms/ReceivingTypeForm";
import PickupForm from "../checkout/forms/PickupForm";
import DeliveryForm from "../checkout/forms/DeliveryForm";
import OrderTimeForm from "../checkout/forms/OrderTimeForm";
import PaymentForm from "../checkout/forms/PaymentForm";
import CustomerForm from "../checkout/forms/CustomerForm";
import {OrderData, PaymentType, ReceivingType} from "../../../../api/dto/checkout.dto";
import Card from "../checkout/Card";

export const receivingTypeMapping: { [key in ReceivingType]: number } = {
    DELIVERY: 1,
    PICKUP: 2
}

export const paymentTypeMapping: { [key in PaymentType]: number } = {
    CASH: 0,
    CARD: 1
}

export type CheckoutStep =
    "RECEIVING_TYPE"
    | "PICKUP_POINT"
    | "DELIVERY_ADDRESS"
    | "ORDER_TIME"
    | "PAYMENT"
    | "CUSTOMER"

export type CheckoutForm = React.FC<{ nextStepCallback: (finalData?: CheckoutData) => void }>

export const CheckoutForms: { [key in CheckoutStep]: CheckoutForm } = {
    RECEIVING_TYPE: ReceivingTypeForm,
    PICKUP_POINT: PickupForm,
    DELIVERY_ADDRESS: DeliveryForm,
    ORDER_TIME: OrderTimeForm,
    PAYMENT: PaymentForm,
    CUSTOMER: CustomerForm
}

export type CheckoutSteps = (CheckoutStep | ((data: Data) => CheckoutStep))[]

const AuthorizedSteps: CheckoutSteps = [
    'RECEIVING_TYPE',
    (data) =>
        data.checkoutData.receivingType === 'DELIVERY'
            ?
            'DELIVERY_ADDRESS'
            :
            "PICKUP_POINT",
    'ORDER_TIME',
    'PAYMENT'
]

const NotAuthorizedSteps: CheckoutSteps = [
    'RECEIVING_TYPE',
    'CUSTOMER',
    (data) =>
        data.checkoutData.receivingType === 'DELIVERY'
            ?
            'DELIVERY_ADDRESS'
            :
            "PICKUP_POINT",
    'ORDER_TIME',
    'PAYMENT'
]


const CheckoutPage: React.FC = () => {

    useEffect(() => {
        (window as any).ym(93650254, 'hit', '/checkout')
    }, [])

    const data = useContext(DataContext)
    const [currentStepNumber, setCurrentStepNumber] = useState(0)
    const steps = useMemo(() => data.user.rolikUser ? AuthorizedSteps : NotAuthorizedSteps, [data.user.rolikUser])
    const currentStep = useMemo(() => {
        const step = steps[currentStepNumber]
        if (typeof step === 'string') {
            return step
        } else {
            return step(data)
        }
    }, [steps, currentStepNumber, data])
    const navigate = useNavigate()

    const handleBack = useCallback(() => {
        if (currentStepNumber !== 0) {
            setCurrentStepNumber(currentStepNumber - 1)
        } else {
            navigate(-1)
        }
    }, [currentStepNumber, navigate])


    const shopPopup = useShowPopup()

    const buildOrderData = useCallback((checkoutData: CheckoutData): OrderData | undefined => {
        if (checkoutData.paymentType && checkoutData.receivingType) {
            return {
                order: {
                    customerName: '',
                    customerPhone: '',
                    customerAddress: checkoutData.deliveryAddress?.address || '',
                    customerHouse: checkoutData.deliveryAddress?.house || -1,
                    customerEntrance: checkoutData.deliveryAddress?.entrance || '',
                    customerFlat: checkoutData.deliveryAddress?.flat || '',
                    customerFloor: checkoutData.deliveryAddress?.floor || '',
                    deliveryType: receivingTypeMapping[checkoutData.receivingType],
                    paymentType: paymentTypeMapping[checkoutData.paymentType],
                    deliveryCost: checkoutData.deliveryCost || 0,
                    orderComment: checkoutData.orderComment || '',
                    persons: 1,
                    customerPrivateHouse: checkoutData.deliveryAddress?.privateHouse || false,
                    note: checkoutData.cashChangeBill || 0,
                    getPoint: checkoutData.pickupPoint || '',
                    preorder: checkoutData.orderType === 'PREORDER',
                    birthday: false,
                    preorderTime: checkoutData.preOrderTime || '',
                    intercomNotWorks: checkoutData.deliveryAddress?.intercomNotWorks || false,
                    isShort: checkoutData.paymentType === 'CARD' || !checkoutData.cashChangeBill,
                    userId: 0,
                    bonuses: 0,
                    user_key: '',
                    user_ip: '',
                    fullAddress: checkoutData.deliveryAddress?.address || ''
                },
                cart: {
                    coupon: data.cartData.coupon || '',
                    products: data.cartData.items.map(i => ({id: String(i.id), count: i.count}))
                }
            }
        }

    }, [data.cartData])

    const makeOrder = useCallback((checkoutData: CheckoutData) => {
        const orderData = buildOrderData(checkoutData)
        if (orderData) {
            orderData.order.user_ip = data.user.ip
            orderData.order.customerName = data.user.name
            orderData.order.customerPhone = data.user.phoneNumber || ''
            if (!data.user.rolikUser) {
                return fetch(`${process.env.REACT_APP_BOT_URL}/makeOrderUnauthorized`, {
                    method: 'POST',
                    body: JSON.stringify(
                        {
                            userId: data.user.id,
                            orderData: JSON.stringify({
                                city: data.user.city.id,
                                data: orderData
                            })
                        }
                    )
                })
                    .then(res => {
                        if (!res.ok) {
                            throw new Error()
                        } else {
                            return res
                        }
                    })
                    .then(_ => shopPopup({
                        title: 'Спасибо за заказ',
                        message: 'Для завершения заказа необходимо подтвердить номер своего телефона.\nДля этого нажмите кнопку, чтобы закрыть магазин и вернуться в нашего бота.',
                        buttons: [{
                            id: 'success_registration',
                            text: 'Вернуться в бота'
                        }]
                    })).catch(_ => shopPopup({
                        title: 'Произошла ошибка!',
                        message: 'Пожалуйста, попробуйте ещё раз!',
                        buttons: [{
                            id: 'error_registration',
                            text: 'Закрыть'
                        }]
                    }))

            } else {
                orderData.order = {
                    ...orderData.order,
                    user_ip: data.user.ip,
                    userId: data.user.rolikUser.id,
                    bonuses: data.user.rolikUser.bonuses,
                    birthday: data.user.rolikUser.isBirthday
                }
                return fetch(`${process.env.REACT_APP_BOT_URL}/makeOrder`, {
                    method: 'POST',
                    body: JSON.stringify(
                        {
                            userId: data.user.id,
                            orderData: {
                                city: data.user.city.id,
                                secret: data.user.rolikUser.secret,
                                data: orderData
                            }
                        }
                    )
                })
                    .then(async (res) => {
                        if (!res.ok) {
                            const text = await res.text()
                            throw new Error(text)
                        }
                        return res.json()
                    })
                    .then(json => shopPopup({
                        title: `Заказ принят!`,
                        message: `Спасибо за заказ, ${data.user.name}\nЗаказ №${json.id} от ${json.date} принят.\nНажмите кнопку, чтобы закрыть магазин и вернуться в нашего бота.`,
                        buttons: [{
                            id: 'success_order',
                            text: 'Вернуться в бота'
                        }]
                    })).catch(err => shopPopup({
                        title: 'Произошла ошибка!',
                        message: `${err.message ? err.message : "Во время создания заказа произошла ошибка"}.\nПожалуйста, попробуйте перезагрузить приложение, нажав на кнопку ниже.`,
                        buttons: [{
                            id: 'error_order',
                            text: 'Перезагрузить'
                        }]
                    }))
            }
        }

    }, [data.user, buildOrderData, shopPopup])


    const handleNext = useCallback((finalData?: CheckoutData) => {
        const nextStep = currentStepNumber + 1
        if (nextStep === steps.length && finalData) {
            const tg = (window as any).Telegram.WebApp;
            const orderPromise = makeOrder(finalData)
            if (orderPromise) {
                orderPromise
                    .then(id => {
                        if (id === 'error_order') {
                            data.updateCheckoutCache({})
                            data.updateCheckoutData({})
                            navigate("/cart")
                        } else {
                            if (id === 'success_order') {
                                (window as any).ym(93650254, 'reachGoal', 'makeOrder')
                                data.clearCart()
                                tg.close()
                            }
                            if (id === 'success_registration') {
                                (window as any).ym(93650254, 'reachGoal', 'first_order')
                                tg.close()
                            }
                        }
                    })
            }
        } else {
            setCurrentStepNumber(currentStepNumber + 1)
        }
    }, [currentStepNumber, steps, makeOrder, data, navigate])


    return (
        <styles.Container>
            <>
                <BackButton onClick={handleBack}/>
                <Stepper steps={steps.length} activeStep={currentStepNumber + 1}/>
                {
                    (() => {
                        const Component = CheckoutForms[currentStep]
                        return <Component nextStepCallback={handleNext}/>
                    })()
                }
                <Card title='Стоимость'>
                    <styles.CheckoutCostContainer>
                        <styles.CartCostContainer>
                            <styles.CheckoutCostLabel>
                                Товаров на:
                            </styles.CheckoutCostLabel>
                            <styles.CostContainer>
                                {data.cartData.discountCost
                                    ?
                                    <>
                                        <styles.Cost>
                                            {data.cartData.discountCost}
                                        </styles.Cost>
                                        <styles.OldCost>
                                            {data.cartData.cost}
                                        </styles.OldCost>
                                        <styles.Cost>
                                            ₽
                                        </styles.Cost>
                                    </>
                                    :
                                    <>
                                        <styles.Cost>
                                            {data.cartData.cost} ₽
                                        </styles.Cost>
                                    </>
                                }
                            </styles.CostContainer>

                        </styles.CartCostContainer>
                        {
                            data.checkoutData.receivingType !== 'PICKUP' &&
                            <styles.DeliveryContainer>
                                <styles.CheckoutCostLabel>
                                    Доставка:
                                </styles.CheckoutCostLabel>

                                <styles.DeliveryCost cost={data.checkoutData.deliveryCost}>
                                    {
                                        data.checkoutData.deliveryCost === undefined
                                            ?
                                            'Не рассчитано'
                                            :
                                            data.checkoutData.deliveryCost === 0
                                                ?
                                                'Бесплатно'
                                                :
                                                data.checkoutData.deliveryCost
                                    }
                                </styles.DeliveryCost>
                            </styles.DeliveryContainer>
                        }

                        <styles.CheckoutCostSummaryContainer>
                            <styles.CheckoutCostSummary>
                                Итого:
                            </styles.CheckoutCostSummary>
                            <styles.CheckoutCostSummary>
                                {(data.cartData.discountCost || data.cartData.cost) + (data.checkoutData.deliveryCost || 0)} ₽
                            </styles.CheckoutCostSummary>
                        </styles.CheckoutCostSummaryContainer>
                    </styles.CheckoutCostContainer>
                </Card>
            </>
        </styles.Container>
    )
}

export default CheckoutPage