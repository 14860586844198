import React, {useCallback, useContext, useEffect, useState} from "react";
import DataContext from "./DataProvider";
import * as styles from "../styles/sidebar"
import {Menu} from "../../../api/dto/menu.dto";
import {useLocation, useNavigate} from "react-router-dom";
import {ClickAwayListener} from "@mui/material";

interface NavItemProps {
    selected?: boolean,
    menu: Menu,
    nested?: boolean

    onSelect(m: Menu): any
}

const NavItem: React.FC<NavItemProps> = ({menu, selected, onSelect, nested}) => {

    const handleSelect = useCallback(() => {
        onSelect(menu)
    }, [menu, onSelect])

    return <styles.NavItemContainer key={menu.id} nested={nested} onClick={handleSelect}>
        <styles.NavItemTitle selected={selected}>
            {menu.title}
        </styles.NavItemTitle>
    </styles.NavItemContainer>
}

interface ExpandableNavItemProps {
    selected?: number,
    menu: Menu,

    onSelect(m: Menu): any
}


const ExpandableNavItem: React.FC<ExpandableNavItemProps> = ({menu, selected, onSelect}) => {

    const [opened, setOpened] = useState(false)

    useEffect(() => {
        if (selected && menu.submenus?.find(m => m.id === selected)) {
            setOpened(true)
        }
    }, [selected, menu])

    const handleSelect = useCallback((m: Menu) => {
        onSelect(m)
    }, [onSelect])

    const handleOpen = useCallback(() => {
        setOpened(!opened)
    }, [opened])

    return (
        <>
            <styles.NavItemContainer key={menu.id} onClick={handleOpen}>
                <styles.NavItemTitle>
                    {menu.title}
                </styles.NavItemTitle>
                {
                    opened
                        ?
                        <styles.ArrowUp/>
                        :
                        <styles.ArrowDown/>
                }
            </styles.NavItemContainer>
            {opened && menu.submenus &&
                menu.submenus.map(m => <NavItem menu={m} onSelect={handleSelect} nested selected={m.id === selected}/>)
            }
        </>
    )
}

const SideBar: React.FC = () => {

    const data = useContext(DataContext)
    const [opened, setOpened] = useState(false)
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState<number>()

    useEffect(() => {
        const match = location.pathname.match(/\d+/)
        setSelectedMenu(match ? Number(match) : undefined)
    }, [location.pathname])

    const navigate = useNavigate()

    const handleMenuSelect = useCallback((m: Menu) => {
        if (m.submenus) {
            navigate(`/${m.id}`)
        } else {
            navigate(`/menu/${m.id}`)
        }
        setOpened(false)
    }, [navigate])

    const handleMain = useCallback(() => {
        navigate("")
        setOpened(false)
    }, [])

    return (
        <>
            <styles.OpenButton onClick={() => setOpened(true)}>
                <styles.OpenButtonTile/>
                <styles.OpenButtonTile/>
                <styles.OpenButtonTile/>
            </styles.OpenButton>
            {opened &&
                <styles.ModalContainer>
                    <ClickAwayListener onClickAway={() => setOpened(false)}>
                        <styles.SideBarContainer>
                            <styles.SidebarHeader>
                                <styles.HeaderLogo onClick={handleMain}/>
                            </styles.SidebarHeader>
                            <styles.NavigationContainer>
                                {data.menus.map(m =>
                                    m.submenus
                                        ?
                                        <ExpandableNavItem menu={m} onSelect={handleMenuSelect}
                                                           selected={selectedMenu}/>
                                        :
                                        <NavItem menu={m} onSelect={handleMenuSelect} selected={selectedMenu === m.id}/>
                                )}
                            </styles.NavigationContainer>
                        </styles.SideBarContainer>
                    </ClickAwayListener>
                </styles.ModalContainer>
            }
        </>
    )
}

export default SideBar