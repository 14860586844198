import React from "react";
import * as styles from "../../styles/checkout/stepper"

interface Props {
    steps: number
    activeStep: number
}

const Stepper: React.FC<Props> = ({steps, activeStep}) => {

    return (
        <styles.StepperContainer>
            <styles.StepperTextContainer>
                <styles.StepperText>
                    Этап{' '}
                </styles.StepperText>
                <styles.StepsText>
                    {activeStep}{' '}
                </styles.StepsText>
                <styles.StepperText>
                    из{' '}
                </styles.StepperText>
                <styles.StepsText>
                    {steps}
                </styles.StepsText>
            </styles.StepperTextContainer>
            <styles.StepsContainer>
                {Array.from(Array(steps).keys())
                    .map(i =>
                        <styles.Step key={i} active={i < activeStep}/>)}
            </styles.StepsContainer>
        </styles.StepperContainer>
    )
}

export default Stepper