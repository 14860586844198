import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
`

export const DeliveryFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const AddressDescriptionContainer = styled.div`
  padding-top: 1rem;
`

export const AddressDescription = styled.span`
  font-size: 1rem;
  color: #6e6e6e;
`

export const AddressDescriptionBold = styled(AddressDescription)`
  font-weight: bold;
`

export const RequiredMark = styled(AddressDescription)`
  color: #dc3545;
`

export const AddressInputContainer = styled.div`
  padding-top: .5rem;
  width: 100%;

  ymaps[class*="search__suggest"] {
    left: 0;
    right: 0;
  }
`

export const AddressInput = styled.input`
  width: 100%;
  color: #5f5f5f;
  border: 1px solid #d6d6d6;
  padding: .4rem .5rem;

  &:focus {
    outline: none;
    border-color: black;
  }
`

export const AddressApplyButtonContainer = styled.div`
  padding-top: 1rem;
`

export const AddressApplyButton = styled.button`
  padding: .5rem 1rem;
  border-radius: 2px;
  outline: none;
  background-color: #c50000;
  font-size: 1rem;
  color: #fff;
  border: none;

  &:focus {
    outline: none;
  }
`

export const ButtonLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ApplyResultContainer = styled.div`
  width: 100%;
  padding-top: .5rem;
`

export const ApplyResult = styled.p<{ $result: boolean }>`
  margin: 0;
  font-size: 1rem;
  color: ${({$result}) => $result ? '#1BCDA1' : '#c50000'};
`

export const PrivateHouseContainer = styled.div`
  width: 100%;
  padding-top: 1rem;
`

export const AddressDetailsContainer = styled.div<{ disabled?: boolean }>`
  padding-top: 1rem;
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 1rem;
  ${({disabled}) => disabled && {opacity: .3}}
`

export const DetailContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: .5rem
`

export const DetailLabelContainer = styled.div`
  display: flex;
`

export const DetailLabel = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #6e6e6e;
`

export const DetailInput = styled.input`
  width: 100%;
  height: 1.5rem;
  color: #5f5f5f;
  border: 1px solid #d6d6d6;
`

export const DoorPhoneContainer = styled.div`
  width: 100%;
  padding-top: 1rem;
`