import React, {useContext, useEffect, useState} from "react";
import {Product} from "../../../api/dto/product.dto";

import * as styles from "../styles/selectedProduct"
import DataContext from "./DataProvider";
import CartItemButton from "./CartItemButton";
import {BaseCartItem, CartItem} from "../../../api/dto/cart.dto";
import {useEffectOnce} from "react-use";
import {ClipLoader} from "react-spinners";
import {ClickAwayListener} from "@mui/material";

const isCartItem = (i: CartItem | Product): i is CartItem => 'count' in i

const isBaseCartItem = (item: CartItem): item is BaseCartItem => {
    return item.hasOwnProperty("cost") && item.hasOwnProperty("oldCost")
}

interface Props {
    item: Product | CartItem
    computeSummary?: boolean
    onlyCount?: boolean

    onClose(): any

    onChange?(): any

    onStartChanging?(): any
}

export const SelectedProduct: React.FC<Props> = ({
                                                     item,
                                                     onClose,
                                                     computeSummary,
                                                     onlyCount,
                                                     onChange,
                                                     onStartChanging
                                                 }) => {

    const data = useContext(DataContext)
    const [product, setProduct] = useState<Product>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const overflow = document.body.style.overflow
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = overflow
        }
    }, [])

    useEffectOnce(() => {
        if (isCartItem(item)) {
            setLoading(true)
            data.getProduct(item.url)
                .then(res => setProduct(res))
                .finally(() => setLoading(false))
        } else {
            setProduct(item)
        }
    })

    return (
        <styles.ModalContainer>
            <styles.ProductContainer>
                <ClickAwayListener onClickAway={() => onClose()}>
                    <styles.ProductCard>
                        {loading
                            ?
                            <styles.LoadingContainer>
                                <ClipLoader/>
                            </styles.LoadingContainer>
                            :
                            product &&
                            <>
                                <styles.CardHeader>
                                    <styles.ProductTitle>
                                        {item.title}
                                    </styles.ProductTitle>
                                    <styles.CloseIcon onClick={() => onClose()}/>

                                </styles.CardHeader>
                                <styles.CardContent>

                                    <styles.ImageContainer>
                                        <styles.ProductImage url={item.image}/>
                                        {product.recommended &&
                                            <styles.RecommendChip>
                                                Рекоммендуем
                                            </styles.RecommendChip>}
                                    </styles.ImageContainer>
                                    <styles.Description>
                                        {product.description}
                                    </styles.Description>
                                    {(!isCartItem(item) || isBaseCartItem(item)) &&
                                        <styles.CartContainer>
                                            <CartItemButton
                                                item={data.cartItemsData[item.id] || item}
                                                computeSummary={computeSummary}
                                                onlyCount={onlyCount}
                                                onChange={onChange}
                                                onStartChanging={onStartChanging}
                                            />
                                        </styles.CartContainer>}

                                </styles.CardContent>
                            </>


                        }

                    </styles.ProductCard>
                </ClickAwayListener>

            </styles.ProductContainer>
        </styles.ModalContainer>
    )
}

export default SelectedProduct