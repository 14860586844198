import React, {useCallback, useContext, useState} from "react";
import * as checkoutStyles from "../../../styles/checkout";
import * as styles from "../../../styles/checkout/pickupPoint"
import {PickupPointDTO} from "../../../../../api/dto/pickupPoint.dto";
import {useEffectOnce} from "react-use";
import DataContext from "../../DataProvider";
import ClipLoader from "react-spinners/ClipLoader";
import {CheckoutForm} from "../../pages/CheckoutPage";
import NextStepButton from "../NextStepButton";
import Card from "../Card";

const getMinutesCase = (value: number): string => {
    const lastDigit = value % 10
    if ((value < 11 || value > 20) && lastDigit === 1) {
        return "минута"
    }

    if ((value < 11 || value > 20) && lastDigit < 5 && lastDigit > 1) {
        return "минуты"
    }

    return "минут"
}

const PickupForm: CheckoutForm = ({nextStepCallback}) => {

    const data = useContext(DataContext)
    const [loading, setLoading] = useState(!data.checkoutFormsCache.pickupPoints)
    const [points, setPoints] = useState<PickupPointDTO[]>(data.checkoutFormsCache.pickupPoints || [])
    const [selectedPoint, setSelectedPoint] = useState<PickupPointDTO | null>(
        points.find(p => p.id === data.checkoutData.pickupPoint) || null)

    useEffectOnce(() => {
        if (!points.length) {
            fetch(`${process.env.REACT_APP_API_URL}/pickupPoint?cityId=${data.user.city.id}`)
                .then(res => res.json())
                .then(json => {
                    setPoints(json)
                    data.updateCheckoutCache({...data.checkoutFormsCache, pickupPoints: json})
                })
                .finally(() => setLoading(false))
        }
    })

    const handleSelectedPointChange = useCallback((point: PickupPointDTO) => {
        setSelectedPoint(point)
    }, [])

    const handleNext = useCallback(() => {
        if (selectedPoint) {
            if (selectedPoint.id !== data.checkoutData.pickupPoint) {
                data.updateCheckoutCache({...data.checkoutFormsCache, preOrderSlots: undefined})
            }
            data.updateCheckoutData({...data.checkoutData, pickupPoint: selectedPoint.id})
            nextStepCallback()
        }
    }, [selectedPoint, data, nextStepCallback])

    // const handleBack = useCallback(() => {
    //     navigate("../receivingType")
    // }, [navigate])


    return (
        loading
            ?
            <checkoutStyles.LoadingContainer>
                <ClipLoader/>
            </checkoutStyles.LoadingContainer>
            :
            <styles.Container>
                <Card title='Точки самовывоза'>
                    <styles.PickupPointsContainer>
                        <NextStepButton
                            disabled={!selectedPoint}
                            onClick={handleNext}/>
                        {points.map(p =>
                            <styles.PickupPointContainer key={p.id} onClick={() => handleSelectedPointChange(p)}>
                                <styles.PickupPointHeader>
                                    <checkoutStyles.FormRadioLabel>
                                        <checkoutStyles.FormRadioBox
                                            checked={selectedPoint?.id === p.id}
                                            readOnly
                                        />
                                        {p.address}
                                    </checkoutStyles.FormRadioLabel>
                                </styles.PickupPointHeader>
                                <styles.PickupPointFooter>
                                    <styles.FooterText>
                                        Примерное время ожидания:
                                    </styles.FooterText>
                                    <styles.PickupPointWaitingTime>
                                        {p.expectedWaitingTime} {getMinutesCase(p.expectedWaitingTime)}.
                                    </styles.PickupPointWaitingTime>
                                </styles.PickupPointFooter>
                            </styles.PickupPointContainer>
                        )}
                    </styles.PickupPointsContainer>
                </Card>
            </styles.Container>
    )
}

export default PickupForm