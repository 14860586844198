import React, {useCallback, useContext, useMemo, useState} from "react";
import * as styles from "../../styles/menu"
import {Menu} from "../../../../api/dto/menu.dto";
import {BackButton, useShowPopup} from "@vkruglikov/react-telegram-web-app";
import DataContext from "../DataProvider";
import {useNavigate, useParams} from "react-router-dom";
import {Product} from "../../../../api/dto/product.dto";
import Rating from "../Rating";
import CartItemButton from "../CartItemButton";
import SelectedProduct from "../SelectedProduct";


export const MenuPage: React.FC = () => {

    const data = useContext(DataContext)
    const {id} = useParams()
    const navigate = useNavigate();
    const selectedMenu = useMemo(() => {
        return data.menusData[Number(id)]
    }, [id, data.menusData])

    const [selectedPromo, setSelectedPromo] = useState<Product | null>(null)

    // const showPopup = useShowPopup();

    // useEffect(() => {
    //     const tg = (window as any).Telegram.WebApp;
    //     showPopup({
    //         title: 'Заказ почти создан!',
    //         message: 'Для завершения заказа и регистрации на нашем сайте, пожалуйста, поделитесь номером своего телефона',
    //         buttons: [{
    //             type: 'ok'
    //         }]
    //     }).then(() => tg.close())
    // }, [])

    // const [selectedMenu, setSelectedMenu] = useState<Menu | null>(null)
    // const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     setLoading(true)
    //     const params = new URLSearchParams()
    //     params.set("cityId", String(user.cityId))
    //     fetch(`${process.env.REACT_APP_API_URL}/menus/all?` + params).then(res => res.json())
    //         .then(res => {
    //             setMenus(res)
    //             setLoading(false)
    //         })
    // }, [user])


    const handleMenuSelect = useCallback((menu: Menu) => {
        if (menu.submenus) {
            navigate(`/${menu.id}`)
        } else {
            navigate(`/menu/${menu.id}`)
        }
    }, [navigate])

    const handleBack = useCallback(() => {
        navigate(`/`)
    }, [navigate])

    const handlePromo = useCallback(() => {
        navigate(`/menu/${data.menuUrlsData['aktsionnye_sety'].id}`)
    }, [navigate, data.menuUrlsData])

    const handlePromoSelect = useCallback((p: Product) => {
        setSelectedPromo(p)
    }, [])

    const handlePromoClose = useCallback(() => {
        setSelectedPromo(null)
    }, [])

    return (
        <styles.MenuContainer>
            {selectedMenu && <BackButton onClick={handleBack}/>}
            {!selectedMenu &&
                <styles.MenuSection>
                    {selectedPromo &&
                        <SelectedProduct item={selectedPromo} onClose={handlePromoClose} computeSummary/>
                    }
                    <styles.MenuTitle>
                        Акционные сеты
                    </styles.MenuTitle>
                    <styles.PromoItemsContainer>
                        {data.promoProducts.map(p =>
                            <styles.PromoItemContainer key={p.id}>
                                <styles.PromoItemImage url={p.image} onClick={() => handlePromoSelect(p)}/>
                                <styles.PromoItemNameContainer>
                                    <styles.PromoItemName>
                                        {p.title}
                                    </styles.PromoItemName>
                                    <Rating/>
                                </styles.PromoItemNameContainer>
                                <styles.PromoItemFooterContainer>
                                    <styles.PromoItemPriceContainer>
                                        <styles.PromoItemPrice>
                                            {p.newPrice} ₽
                                        </styles.PromoItemPrice>
                                        <styles.PromoItemOldPrice>
                                            {p.oldPrice} ₽
                                        </styles.PromoItemOldPrice>
                                    </styles.PromoItemPriceContainer>
                                    <styles.PromoCartContainer>
                                        <CartItemButton item={data.cartItemsData[p.id] || p} computeSummary/>
                                    </styles.PromoCartContainer>
                                </styles.PromoItemFooterContainer>
                            </styles.PromoItemContainer>
                        )}
                        <styles.PromoShowMoreButton onClick={handlePromo}>
                            Показать ещё
                        </styles.PromoShowMoreButton>
                    </styles.PromoItemsContainer>
                </styles.MenuSection>
            }
            <styles.MenuSection>
                <styles.MenuTitle>
                    {selectedMenu?.title || "Меню"}
                </styles.MenuTitle>
                {<styles.MenuItemsContainer>
                    {(selectedMenu?.submenus ? selectedMenu.submenus : data.menus).map(menu =>
                        <styles.MenuItemContainer key={menu.id} onClick={() => handleMenuSelect(menu)}>
                            <styles.MenuItemImage url={menu.image}/>
                            <styles.MenuItemText>
                                {menu.title}
                            </styles.MenuItemText>
                        </styles.MenuItemContainer>
                    )}
                </styles.MenuItemsContainer>
                }
            </styles.MenuSection>


        </styles.MenuContainer>
    )
}


export default MenuPage