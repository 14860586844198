import React, {useCallback, useContext, useMemo, useState} from "react";
import {BaseCartItem} from "../../../api/dto/cart.dto";
import * as styles from "../styles/category/cart"
import DataContext from "./DataProvider";
import {Product} from "../../../api/dto/product.dto";
import {ClipLoader} from "react-spinners";

interface Props {
    item: BaseCartItem | Product
    computeSummary?: boolean
    onlyCount?: boolean

    onChange?(): any

    onStartChanging?(): any
}

const isCartItem = (item: Props['item']): item is BaseCartItem => 'count' in item && 'cost' in item && 'oldCost' in item
const convertProduct = (product: Product, count: number): BaseCartItem => {
    return {
        ...product,
        count: count,
        cost: product.newPrice,
        oldCost: product.oldPrice
    }
}

export const CartItemButton: React.FC<Props> = ({item, computeSummary, onlyCount, onChange, onStartChanging}) => {

    const data = useContext(DataContext)
    const [fetching, setFetching] = useState(false)
    const fetchingCallback = useMemo(() => {
        return !!onStartChanging && !!onChange
    }, [onStartChanging, onChange])

    const handleAddToCart = useCallback(() => {
        if (!fetching) {
            if (!isCartItem(item)) {
                onStartChanging && onStartChanging()
                setFetching(true)
                data.updateCartItem(convertProduct(item, 1), computeSummary).finally(() => {
                    setFetching(false)
                    onChange && onChange()
                })
            }
        }

    }, [data, item, computeSummary, onStartChanging, onChange, fetching])

    const handleUpdateCount = useCallback((op: 'add' | 'sub') => {
        if (!fetching) {
            if (isCartItem(item)) {
                onStartChanging && onStartChanging()
                setFetching(true)
                data.updateCartItem({
                    ...item,
                    count: item.count + (op === 'add' ? 1 : -1)
                }, computeSummary).finally(() => {
                    setFetching(false)
                    onChange && onChange()
                })
            } else {
                handleAddToCart()
            }
        }

    }, [data, item, computeSummary, onStartChanging, onChange, handleAddToCart, fetching])


    return (
        <styles.CartButtonContainer>
            {isCartItem(item) || onlyCount
                ?
                <>
                    <styles.RemoveIcon onClick={() => handleUpdateCount('sub')}/>
                    <styles.CountText>
                        {!fetchingCallback && (fetching)
                            ?
                            <styles.UpdateLoadingContainer>
                                <ClipLoader size='1em' color='inherit'/>
                            </styles.UpdateLoadingContainer>
                            :

                            isCartItem(item) ? item.count : 0


                        }
                    </styles.CountText>

                    <styles.AddIcon onClick={() => handleUpdateCount('add')}/>
                </>
                :
                <styles.CartButton onClick={handleAddToCart}>
                    {!fetchingCallback && fetching
                        ?
                        <>
                            <styles.AddLoadingContainer>
                                <ClipLoader size='100%' color='inherit'/>
                            </styles.AddLoadingContainer>
                            <styles.ButtonText>
                                В корзину
                            </styles.ButtonText>
                        </>
                        :
                        <>
                            <styles.ButtonIcon/>
                            <styles.ButtonText>
                                В корзину
                            </styles.ButtonText>
                        </>

                    }
                </styles.CartButton>
            }
        </styles.CartButtonContainer>
    )
}

export default CartItemButton