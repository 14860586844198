import styled from "styled-components";
import {ReactComponent as TrashIcon} from "../icons/trash.svg";
import {ReactComponent as ArrowUpIcon} from "../icons/arrow_up.svg";
import {ReactComponent as ArrowDownIcon} from "../icons/arrow_down.svg";
import {ReactComponent as CloseIcon} from "../icons/close.svg"

export const CartContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem;
  gap: 1rem;
`

export const CartTitle = styled.span`
  font-size: 1.5rem;
`

export const EmptyCartTitle = styled.span`
  margin-top: 50%;
  text-align: center;
  font-size: 1rem;
  color: #9e9e9e;
`

export const CartCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-bottom: 1px solid #f2f2f2;
`

export const CartPriceContainer = styled.div`
  padding: 1rem .5rem .5rem;
  font-size: 1.5rem;
  display: flex;
  gap: 0.5rem;
  width: 100%;
  flex-wrap: wrap;
`

export const PriceLabel = styled.span`
  white-space: nowrap;
  color: black;
  font-weight: 600;
`

export const PriceContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const Price = styled(PriceLabel)`
`

export const OldPrice = styled(Price)`
  text-decoration: line-through;
  opacity: 0.5;
`

export const CardHeaderContainer = styled.div`
  width: 100%;
  padding: 1rem .5rem .5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CartItemsContainer = styled.div`
  width: 100%;
  padding: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: .2rem;
`


export const ClearCartIcon = styled(TrashIcon)`
  fill: #c50000;
  width: 1.5rem;
  height: 1.5rem;
`


export const CartItemContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: .5rem;
  border-bottom: 1px solid #f2f2f2;
`

export const CartItemDescriptionContainer = styled.div`
  flex: 1;
  display: flex;
  gap: .2rem;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;
`

export const CartItemTitleContainer = styled.div`
  flex: 1;
  text-align: left;
`


export const CartItemTitle = styled.span`
  font-size: 1rem;
`

export const CartImageContainer = styled.div`
  position: relative;
  flex: 1;
  height: auto;
`

interface ImageProps {
    url: string
}

export const CartImage = styled.img.attrs<ImageProps>(({url}) => ({
    src: url
}))<ImageProps>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 2px;
`

export const CartItemPriceContainer = styled.div`
  flex: 1;
  text-align: left;
  overflow-wrap: normal;
`

export const CartItemPrice = styled.span`
  font-size: 1rem;
  color: #9e9e9e;
`


export const CartItemCountContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
`

export const PromoCodeContainer = styled.div`
  //border-top: 1px solid #9e9e9e;
  //border-bottom: 1px solid #9e9e9e;
  background: #fff;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem .5rem;
`

export const PromoCodeTitleContainer = styled.div`
  font-size: 1rem;
`

export const PromoCodeTitle = styled.span`
  color: #9e9e9e
`

export const PromoCodeTitleAlt = styled.span`
  color: black;
`

export const PromoCodeInputContainer = styled.div`
  display: flex;
  gap: .5rem;
  justify-content: center;
  padding: 1rem .5rem;
  max-width: 100%;
`

export const InputContainer = styled.div`
  position: relative;
`

export const PromoCodeInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 1rem;
  border-radius: 2px;
  border: 1px solid #D9D9D9;
  background: #FBFCFD;
  outline: none;
`

export const ClearCouponIcon = styled(CloseIcon)`
  width: .5rem;
  height: .5rem;
  fill: #D9D9D9;
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
`


export const PromoCodeButton = styled.button`
  padding: .4rem .8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: #c50000;
  outline: none;
  border: none;
`

export const ButtonText = styled.span`
  color: white;
  font-size: 1rem;
`

interface PromoCodeDescriptionProps {
    valid: boolean
}

export const PromoCodeDescription = styled.span<PromoCodeDescriptionProps>`
  font-size: 1rem;
  color: ${({valid}) => valid ? '#1BCDA1' : '#c50000'};
`

export const ShowAllContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-bottom: 1rem;
`

export const ShowAllButton = styled.button`
  display: flex;
  gap: .5em;
  align-items: center;
  justify-content: left;
  background: none;
  border: none;
  outline: none;
  color: #c50000;
`

export const ShowAllText = styled.span`
  font-size: 1rem;
`

const ArrowIcon = styled.svg`
  fill: #c50000;
  width: 1rem;
  height: 1rem
`

export const ArrowUp = styled(ArrowIcon).attrs(() => ({as: ArrowUpIcon}))``
export const ArrowDown = styled(ArrowIcon).attrs(() => ({as: ArrowDownIcon}))``

export const LoadingModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 1000;
  background-color: rgba(232, 232, 232, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LoadingText = styled.span`
  font-size: 1.5rem;
`