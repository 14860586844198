import styled from "styled-components";
import {ReactComponent as CloseIconSVG} from "../icons/close.svg";

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: rgba(232, 232, 232, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductContainer = styled.div`
  position: relative;
  width: 90%;
  height: 80%;
`

export const ProductCard = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0 0 46px rgba(228, 225, 212, .3);
  max-height: 100%;
  min-height: 0;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: none;
  padding-bottom: 1rem;
`

export const CardContent = styled.div`
  overflow-y: auto;
  min-height: 0;
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ProductTitle = styled.span`
  font-weight: 600;
  font-size: 1rem;
`

export const ImageContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: auto;
  position: relative;
`

export const ProductImage = styled.img.attrs<{ url: string }>(({url}) => ({
    src: url
}))<{ url: string }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 2px;
`

export const RecommendChip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: .2rem .4rem;
  font-size: .8rem;
  background-color: #1bcda1;
  border-radius: 13px;
  color: white;
`

export const Description = styled.span`
  white-space: pre-line;
  margin-top: .5rem;
  width: 100%;
`

export const CartContainer = styled.div`
  width: 100%;
  padding-top: 1rem;
  padding-bottom: .5rem;
  font-size: 1.5rem;
`

export const CloseIcon = styled(CloseIconSVG)`
  fill: #9e9e9e;
  width: 1rem;
  height: 1rem;
  z-index: 100;
`