import {CheckoutForm} from "../../pages/CheckoutPage";
import * as styles from "../../../styles/checkout/deliveryTime";
import * as checkoutStyles from "../../../styles/checkout";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {OrderTimeType} from "../../../../../api/dto/checkout.dto";
import DataContext from "../../DataProvider";
import {GetPreOrderTimesDTO} from "../../../../../api/dto/preOrderTime.dto";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DateTimeValidationError, LocalizationProvider, MobileDateTimePicker, ruRU} from "@mui/x-date-pickers";
import moment from "moment";
import 'moment/locale/ru';
import {ClipLoader} from "react-spinners";
import NextStepButton from "../NextStepButton";
import Card from "../Card";


const OrderTimeForm: CheckoutForm = ({nextStepCallback}) => {

    const data = useContext(DataContext)
    const [selectedType, setSelectedType] = useState<OrderTimeType | null>(data.checkoutData.orderType || null)
    const [commentInput, setCommentInput] = useState(data.checkoutData.orderComment || "")
    const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(data.checkoutData.preOrderTime ? moment(data.checkoutData.preOrderTime) : null)
    const [selectedDateError, setSelectedDateError] = useState<DateTimeValidationError | null>(null)


    const [preOrderSlots, setPreOrderSlots] = useState<string[] | null>(data.checkoutFormsCache.preOrderSlots || null)
    const [slotsLoading, setSlotsLoading] = useState(false)

    const pickerMessage = useMemo(() => {
        if (!selectedDate) {
            return 'Пожалуйста, выберите дату и время'
        }
        if (slotsLoading) {
            return 'Загрузка'
        }
        if (selectedDateError) {
            return 'Пожалуйста, выберите доступные дату и время'
        }
    }, [selectedDateError, selectedDate, slotsLoading])


    const updateSlots = useCallback((date: moment.Moment) => {
        if (data.checkoutData.pickupPoint) {
            setSlotsLoading(true)
            const dto: GetPreOrderTimesDTO = {
                pointId: data.checkoutData.pickupPoint,
                coupon: data.cartData.coupon,
                date: date.format('YYYY-MM-DD')
            }
            const params = new URLSearchParams()
            Object.entries(dto).forEach(([k, v]) => v && params.set(k, v))
            fetch(`${process.env.REACT_APP_API_URL}/preOrderTime?` + params)
                .then(res => res.json())
                .then(json => setPreOrderSlots(json))
                .finally(() => setSlotsLoading(false))
        }
    }, [data.checkoutData.pickupPoint, data.cartData.coupon])


    useEffect(() => {
        if (selectedDate && !preOrderSlots) {
            updateSlots(selectedDate)
        }
    }, [selectedDate, preOrderSlots, updateSlots])


    const handleTypeChange = useCallback((newType: OrderTimeType) => {
        setSelectedType(newType)
        // if (newType === 'PREORDER') {
        //     updateSlots()
        // }
    }, [])

    const handleDateSelect = useCallback((value: moment.Moment) => {
        if (!selectedDate || !value.isSame(selectedDate, 'day')) {
            updateSlots(value)
        }
    }, [updateSlots, selectedDate])

    const disableTimeSlots = useCallback((value: moment.Moment): boolean => {
        const formatted = value.format("HH:mm")
        return !preOrderSlots?.find(s => s === formatted);
    }, [preOrderSlots])

    const maxPreOrderDate = useMemo(() => moment(data.cartData.maxPreOrderDate), [data.cartData.maxPreOrderDate])

    const disableDates = useCallback((value: moment.Moment): boolean => {
        return value.isAfter(maxPreOrderDate, 'day')
    }, [maxPreOrderDate])

    const handleNext = useCallback(() => {
        if (selectedType) {
            data.updateCheckoutData({
                ...data.checkoutData,
                orderType: selectedType,
                preOrderTime: selectedDate?.format('yyyy-MM-DD HH:mm'),
                orderComment: commentInput
            })
            data.updateCheckoutCache({...data.checkoutFormsCache, preOrderSlots: preOrderSlots || undefined})
            nextStepCallback()
        }

    }, [data, selectedDate, selectedType, nextStepCallback, commentInput, preOrderSlots])


    return (
        <styles.Container>
            <Card>
                <styles.DeliveryTimeContainer>
                    <NextStepButton
                        disabled={!selectedType || (selectedType === 'PREORDER' && (!selectedDate || !!selectedDateError))}
                        onClick={handleNext}/>

                    <styles.TimeTypesContainer>
                        {data.user.city.isWork &&
                            <styles.TimeTypeContainer>
                                <checkoutStyles.FormRadioLabel>
                                    <checkoutStyles.FormRadioBox
                                        checked={selectedType === 'ORDER'}
                                        onChange={(e) => e.target.value && handleTypeChange("ORDER")}
                                    />
                                    Как можно быстрее
                                </checkoutStyles.FormRadioLabel>
                            </styles.TimeTypeContainer>
                        }
                        {data.cartData.preOrderAvailable &&
                            <styles.TimeTypeContainer>
                                <checkoutStyles.FormRadioLabel>
                                    <checkoutStyles.FormRadioBox
                                        checked={selectedType === 'PREORDER'}
                                        onChange={(e) => e.target.value && handleTypeChange("PREORDER")}
                                    />
                                    К определенному времени (предзаказ)
                                </checkoutStyles.FormRadioLabel>
                            </styles.TimeTypeContainer>
                        }
                    </styles.TimeTypesContainer>
                    {selectedType === "PREORDER" &&
                        <styles.PreOrderContainer>
                            <styles.PreOrderTitle>
                                Выберите дату и время, к которому нужно приготовить ваш заказ
                            </styles.PreOrderTitle>
                            <styles.DatePickerContainer>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='ru'
                                                      localeText={
                                                          ruRU.components.MuiLocalizationProvider.defaultProps.localeText
                                                      }>
                                    <MobileDateTimePicker
                                        value={selectedDate}
                                        onChange={(value) => value && handleDateSelect(value)}
                                        onAccept={(value) => {
                                            setSelectedDate(value)
                                        }}
                                        onError={(err) => setSelectedDateError(err)}
                                        minDate={moment(new Date())}
                                        defaultCalendarMonth={moment(new Date())}
                                        disablePast
                                        loading={slotsLoading}
                                        shouldDisableTime={disableTimeSlots}
                                        shouldDisableDate={disableDates}
                                        minutesStep={30}
                                        viewRenderers={slotsLoading ? {
                                            'hours': () => {
                                                return <styles.TimeLoadingContainer>
                                                    <ClipLoader/>
                                                </styles.TimeLoadingContainer>
                                            }
                                        } : undefined}
                                        slotProps={{
                                            textField: {
                                                helperText: pickerMessage,
                                                size: 'small',
                                                placeholder: undefined,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </styles.DatePickerContainer>
                            {/*<styles.PreOrderDescriptionContainer>*/}
                            {/*    <styles.PreorderDescription>*/}
                            {/*        Рекомендуем делать предзаказ заранее. Если необходимое время не отображается - его уже*/}
                            {/*        заняли.*/}
                            {/*        Пожалуйста, выберите другое.*/}
                            {/*    </styles.PreorderDescription>*/}
                            {/*</styles.PreOrderDescriptionContainer>*/}
                        </styles.PreOrderContainer>
                    }
                    <styles.CommentContainer>
                        <styles.CommentText>
                            Комментарий к заказу
                        </styles.CommentText>
                        <styles.CommentTextArea
                            placeholder="Укажите только важную информацию, если необходимо."
                            value={commentInput}
                            onChange={(e) => setCommentInput(e.target.value)}
                        />
                    </styles.CommentContainer>
                </styles.DeliveryTimeContainer>
            </Card>
        </styles.Container>
    )
}

export default OrderTimeForm