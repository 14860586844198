import React, {useCallback, useEffect, useMemo, useState} from "react"
import {UserDTO} from "../../../api/dto/user.dto";
import {Menu} from "../../../api/dto/menu.dto";
import {ClipLoader} from "react-spinners";
import {LoadingContainer} from "../styles";
import {Product} from "../../../api/dto/product.dto";
import {BaseCartItem, CartDTO, CartSummaryDTO, ComputeCartDTO} from "../../../api/dto/cart.dto";
import {
    DeliveryAddressType,
    OrderTimeType,
    PaymentType,
    PickupPointType,
    PreOrderTime,
    ReceivingType
} from "../../../api/dto/checkout.dto";
import {PickupPointDTO} from "../../../api/dto/pickupPoint.dto";
import {DeliveryAreaDTO} from "../../../api/dto/deliveryArea.dto";
import {useShowPopup} from "@vkruglikov/react-telegram-web-app";

type MenusData = Record<number, Menu>
type ProductsData = Record<string, Product>
type MenusUrlData = Record<string, Menu>
type CartProductsData = Record<number, BaseCartItem>

interface CartData extends CartDTO {
    coupon?: string
}

export interface CheckoutData {
    receivingType?: ReceivingType
    pickupPoint?: PickupPointType
    deliveryAddress?: DeliveryAddressType,
    orderType?: OrderTimeType
    preOrderTime?: PreOrderTime
    paymentType?: PaymentType
    deliveryCost?: number
    orderComment?: string
    cashChange?: boolean,
    cashChangeBill?: number
    bonuses?: number
}

type CheckoutCache = {
    pickupPoints?: PickupPointDTO[]
    deliveryZones?: any
    deliveryAreas?: DeliveryAreaDTO[]
    selectedDeliveryZone?: DeliveryAreaDTO
    preOrderSlots?: string[]
}

export interface Data {
    user: UserDTO,
    menus: Menu[],
    menusData: MenusData,
    menuUrlsData: MenusUrlData,
    cartData: CartData
    cartItemsData: CartProductsData
    checkoutData: CheckoutData
    checkoutFormsCache: CheckoutCache
    promoProducts: Product[]

    updateCheckoutData(newData: CheckoutData): any

    updateCheckoutCache(newCache: CheckoutCache): any

    updateUserName(newName: string): any

    updateCartItem(item: BaseCartItem | null, summary?: boolean): Promise<void>

    updateCartCoupon(coupon: string | undefined): Promise<void>

    clearCart(): void

    getProducts(menu: Menu | string): Promise<Product[]>

    getProduct(url: string): Promise<Product>
}

const DataContext = React.createContext<Data>(null!)

interface Props {
    children: React.ReactNode
}

type MenuProductsData = Record<number, Product[]>

const initialCart: CartData = {
    cost: 0,
    count: 0,
    items: [],
    specials: [],
    promotional: [],
    couponValid: false,
    maxPreOrderDate: '',
    deliveryAvailable: false,
    pickupAvailable: false,
    preOrderAvailable: false,
    bonusesAvailable: false,
    bonusesSpend: 0,
    bonusesReceive: 0
}


export const DataProvider: React.FC<Props> = ({children}) => {

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState<UserDTO | null>(null)
    const [menusData, setMenusData] = useState<MenusData | null>(null)
    const [menus, setMenus] = useState<Menu[] | null>(null)
    const [menuProductsData, setMenuProductsData] = useState<MenuProductsData>({})
    const [cartData, setCartData] = useState<CartData | null>(null)
    const [clearCachedCart, setClearCachedCart] = useState<boolean | null>(null)
    const [cartItemsData, setCartItemsData] = useState<CartProductsData | null>(null)
    const [checkoutData, setCheckoutData] = useState<CheckoutData>({})
    const [promoProducts, setPromoProducts] = useState<Product[] | null>(null)
    const [productsData, setProductsData] = useState<ProductsData>({})
    const [checkoutCache, setCheckoutCache] = useState<CheckoutCache>({})

    const updateCheckoutCache: Data['updateCheckoutCache'] = useCallback((newCache) => {
        setCheckoutCache(newCache)
    }, [])

    const menusUrlsData = useMemo(() => {
        return menusData ? Object.values(menusData).reduce((data, menu) => {
                data[menu.code] = menu
                return data
            }, {} as MenusUrlData)
            : null
    }, [menusData])

    const getProducts: Data['getProducts'] = useCallback((m: Menu | string): Promise<Product[]> => {
        if (user && menusUrlsData) {
            const menu = typeof m === 'string' ? menusUrlsData[m] : m
            if (menuProductsData.hasOwnProperty(menu.id)) {
                return Promise.resolve(menuProductsData[menu.id])
            } else {
                const params = new URLSearchParams()
                params.set("cityId", String(user.city.id))
                params.set("category", menu.code)
                return fetch(`${process.env.REACT_APP_API_URL}/products/all?` + params)
                    .then(res => res.json())
                    .then(json => {
                        const products: Product[] = json
                        setMenuProductsData({...menuProductsData, [menu.id]: products})
                        setProductsData({
                            ...productsData,
                            ...Object.fromEntries(products.map(p => ([p.url, p])))
                        })
                        return json
                    })
                    .catch(_ => [])
            }
        } else {
            return Promise.resolve([])
        }

    }, [user, menuProductsData, menusUrlsData, productsData])

    useEffect(() => {
        const tg = (window as any).Telegram.WebApp;

        fetch(`${process.env.REACT_APP_BOT_URL}/checkData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({_auth: tg.initData})
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("")
                }
                return res
            })
            .then(res => res.json())
            .then(json => {
                setClearCachedCart(!!json.clearCart)
                fetch(`${process.env.REACT_APP_API_URL}/users/${json.data.id}`)
                    .then(res => res.json())
                    .then(json => setUser(json))
            })
            .catch(err => setUser(null))

    }, [])


    useEffect(() => {
        if (user?.city.id && clearCachedCart !== null) {
            Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/menus/all?cityId=${user.city.id}`)
                    .then(res => res.json())
                    .then(json => {
                        const fetchedMenus = json as Menu[]
                        setMenus(fetchedMenus)
                        const allMenus = fetchedMenus.flatMap(m => m.submenus ? [m, ...m.submenus] : [m])
                        const menusData: MenusData = allMenus.reduce((data, m) => {
                            data[m.id] = m
                            return data
                        }, {} as MenusData)
                        setMenusData(menusData)
                        const promo = allMenus.find(m => m.code === "aktsionnye_sety")
                        if (promo) {
                            const params = new URLSearchParams()
                            params.set("cityId", String(user.city.id))
                            params.set("category", promo.code)
                            fetch(`${process.env.REACT_APP_API_URL}/products/all?` + params)
                                .then(res => res.json())
                                .then(json => {
                                    const products: Product[] = json
                                    setMenuProductsData({[promo.id]: products})
                                    setPromoProducts(products.slice(0, 4))
                                    return json
                                })
                        }
                    })
                    .catch(_ => {
                        setMenusData(null)
                        setMenus(null)
                    }),
                (new Promise((resolve, reject) => {
                    const cart: CartData = JSON.parse((!clearCachedCart && localStorage.getItem("cart")) || "{}")
                    const dto: ComputeCartDTO = {
                        items: cart.items,
                        cityId: user.city.id,
                        coupon: cart.coupon
                    }
                    fetch(`${process.env.REACT_APP_API_URL}/cart/compute`, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(dto)
                    })
                        .then(res => res.json())
                        .then(json => {
                            const data = json as CartDTO
                            const fetchedCart: CartData = {
                                ...data,
                                coupon: cart.coupon
                            }
                            localStorage.removeItem("cart")
                            localStorage.setItem("cart", JSON.stringify(fetchedCart))
                            setCartData(fetchedCart)
                            const itemsData = fetchedCart.items.reduce((acc, item) => {
                                acc[item.id] = item
                                return acc
                            }, {} as CartProductsData)
                            setCartItemsData(itemsData)
                        })
                        .catch(_ => {
                            setCartData(initialCart)
                            setCartItemsData({})
                        })
                    resolve(cart)
                }))
            ])
                .finally(() => setLoading(false))
        }
    }, [user?.city.id, clearCachedCart])


    const updateCart: Data["updateCartItem"] = useCallback((item: BaseCartItem | null, computeSummary = false): Promise<void> => {
        if (cartItemsData && cartData && user) {
            const dataCopy = {...cartItemsData}
            if (item) {
                if (item.count) {
                    dataCopy[item.id] = item
                } else {
                    delete dataCopy[item.id]
                }
            }
            const items = Object.values(dataCopy)
            const dto: ComputeCartDTO = {
                items: items,
                coupon: cartData.coupon,
                secret: user.rolikUser?.secret,
                cityId: user.city.id
            }
            return fetch(`${process.env.REACT_APP_API_URL}/cart/${computeSummary ? 'computeSummary' : 'compute'}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(dto)
            })
                .then(res => res.json())
                .then(json => {
                    if (computeSummary) {
                        const data: CartSummaryDTO = json
                        setCartItemsData(dataCopy)
                        const newCart: CartData = {
                            ...initialCart,
                            items: Object.values(dataCopy),
                            ...data
                        }
                        localStorage.setItem("cart", JSON.stringify(newCart))
                        setCartData(newCart)

                    } else {
                        const data: CartDTO = json
                        const newCart: CartData = {
                            ...data, coupon: cartData.coupon
                        }
                        localStorage.setItem("cart", JSON.stringify(newCart))
                        if ((!newCart.discountCost || newCart.discountCost < 599) && newCart.cost < 599) {
                            newCart.deliveryAvailable = false
                        }
                        setCartData(newCart)
                        setCartItemsData(
                            newCart.items.reduce((acc, item) => {
                                acc[item.id] = item
                                return acc
                            }, {} as CartProductsData)
                        )
                    }
                })
        }
        return Promise.reject()
    }, [cartItemsData, cartData, user])

    const clearCart: Data['clearCart'] = useCallback(() => {
        localStorage.clear()
        setCartData(initialCart)
        setCartItemsData({})
    }, [])

    const updateCoupon: Data['updateCartCoupon'] = useCallback((coupon: string) => {
        if (cartData && user) {
            const dto: ComputeCartDTO = {
                cityId: user.city.id,
                items: cartData.items,
                coupon: coupon
            }
            return fetch(`${process.env.REACT_APP_API_URL}/cart/compute`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(dto)
            })
                .then(res => res.json())
                .then(json => {
                    const data: CartDTO = json
                    const newData: CartData = {
                        ...data, coupon: coupon
                    }
                    localStorage.setItem("cart", JSON.stringify(newData))
                    if ((!newData.discountCost || newData.discountCost < 599) && newData.cost < 599) {
                        newData.deliveryAvailable = false
                    }
                    setCartData(newData)
                    setCartItemsData(
                        newData.items.reduce((acc, item) => {
                            acc[item.id] = item
                            return acc
                        }, {} as CartProductsData)
                    )
                })
        }
        return Promise.reject()
    }, [cartData, user])

    const updateCheckoutData: Data['updateCheckoutData'] = useCallback((newData) => {
        setCheckoutData(newData)
    }, [])


    const updateUserName: Data['updateUserName'] = useCallback((newName: string) => {
        if (user) {
            setUser({...user, name: newName})
        }
    }, [user])

    const getProduct: Data['getProduct'] = useCallback((url: string) => {
        if (user?.city.id) {
            if (productsData[url]) {
                return Promise.resolve(productsData[url])
            } else {
                return fetch(`${process.env.REACT_APP_API_URL}/products/${url}?cityId=${user.city.id}`)
                    .then(res => res.json())
                    .then(json => {
                        const p: Product = json
                        productsData[p.url] = p
                        return p
                    })
            }
        }
        return Promise.reject()

    }, [productsData, user?.city.id])

    const showPopup = useShowPopup()

    useEffect(() => {
        const tg = (window as any).Telegram.WebApp;
        if (user?.city.isWork !== undefined && !user.city.isWork) {
            showPopup({
                title: 'Ресторан закрыт',
                message: `Приём заказов с ${user.city.timeStart} до ${user.city.timeEnd}`,
                buttons: [{
                    text: 'Вернуться в бота'
                }]
            }).finally(() => tg.close())
        } else if (user?.city.status === 2) {
            showPopup({
                    message: 'К сожалению, по техническим причинам оформить заказ не получится. Пожалуйста, попробуйте позже.',
                    buttons: [{
                        text: 'Вернуться в бота'
                    }]
                }
            ).finally(() => tg.close())
        } else if (user?.city.status === 3) {
            showPopup({
                    message: 'Служба доставки ROLIK в вашем городе временно не работает.\n' +
                        'Пожалуйста, зайдите позже.',
                    buttons: [{
                        text: 'Вернуться в бота'
                    }]
                }
            ).finally(() => tg.close())
        } else if (user?.city.status === 5) {
            showPopup({
                    message: 'Ресторан сегодня не работает. Выходной. Ждем вас в рабочее время!',
                    buttons: [{
                        text: 'Вернуться в бота'
                    }]
                }
            ).finally(() => tg.close())
        } else if (user?.city.status === 6) {
            showPopup({
                    message: 'Служба ROLIK в Вашем городе ещё не открылась\n' +
                        'Ищем партнера в Вашем городе!',
                    buttons: [{
                        text: 'Вернуться в бота'
                    }]
                }
            ).finally(() => tg.close())
        }

    }, [user?.city.isWork, user?.city.timeStart, user?.city.timeEnd, user?.city.status, showPopup])


    return (
        loading
            ?
            <LoadingContainer>
                <ClipLoader size='3rem'/>
            </LoadingContainer>
            :
            user && menus && menusData && cartItemsData && cartData && promoProducts && menusUrlsData &&
            <DataContext.Provider value={{
                user: user,
                menus: menus,
                menusData: menusData,
                menuUrlsData: menusUrlsData,
                getProducts: getProducts,
                getProduct: getProduct,
                cartItemsData: cartItemsData,
                cartData: cartData,
                promoProducts: promoProducts,
                checkoutData: checkoutData,
                checkoutFormsCache: checkoutCache,
                updateCartItem: updateCart,
                clearCart: clearCart,
                updateCartCoupon: updateCoupon,
                updateCheckoutData: updateCheckoutData,
                updateCheckoutCache: updateCheckoutCache,
                updateUserName: updateUserName
            }}>
                {children}
            </DataContext.Provider>
    )
}

export default DataContext