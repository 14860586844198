import React from "react";
import {MainButton} from "@vkruglikov/react-telegram-web-app";

interface Props {
    disabled: boolean
    text?: string
    loading?: boolean

    onClick(): void
}

const NextStepButton: React.FC<Props> = ({disabled, onClick, text, loading}) => {

    return (
        <MainButton
            progress={loading}
            text={text || "Далее"}
            disable={disabled}
            color={disabled ? "#797979" : "#C50000"}
            textColor={disabled ? "#e3e3e3" : "#FFFFFF"}
            onClick={onClick}
        />
    )
}

export default NextStepButton