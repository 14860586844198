import styled from "styled-components";
import {ReactComponent as Logo} from "../icons/logo.svg"
import {ReactComponent as ArrowUpIcon} from "../icons/arrow_up.svg";
import {ReactComponent as ArrowDownIcon} from "../icons/arrow_down.svg";

export const OpenButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const OpenButtonTile = styled.span`
  width: 1.5rem;
  height: 3px;
  background-color: #ccc;
`

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  background-color: rgba(232, 232, 232, 0.4);
`

export const SideBarContainer = styled.div`
  background-color: white;
  display: flex;
  gap: .2rem;
  flex-direction: column;
  width: 60%;
  max-width: 60%;
  height: 100%;
  overflow-y: hidden;
`

export const SidebarHeader = styled.div`
  width: 100%;
  padding: 0.5rem;
`

export const HeaderLogo = styled(Logo)`
  width: 50%;
`

export const NavigationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: .2rem;
`

export const NavItemContainer = styled.div<{ selected?: boolean, nested?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: .2rem;
  padding: 0.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }

  ${({selected}) => selected && ({backgroundColor: '#fbfcfd'})}
  ${({nested}) => nested && ({paddingLeft: '1rem'})}
`

export const NavItemTitle = styled.span<{ selected?: boolean }>`
  font-size: 1rem;
  color: ${({selected}) => selected ? '#c50000' : '#000000'};
`

const ArrowIcon = styled.svg`
  fill: black;
  width: 1rem;
  height: 1rem;
  flex: none;
`

export const ArrowUp = styled(ArrowIcon).attrs(() => ({as: ArrowUpIcon}))``
export const ArrowDown = styled(ArrowIcon).attrs(() => ({as: ArrowDownIcon}))``