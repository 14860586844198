import * as styles from "../../styles"
import React, {useContext} from "react";
import DataContext from "../DataProvider";
import {useNavigate} from "react-router-dom";
import SideBar from "../SideBar";

const Header: React.FC = () => {

    const data = useContext(DataContext)
    const navigate = useNavigate()

    return (
        <styles.PageHeader>
            <styles.HeaderLogo onClick={() => navigate("")}/>
            <styles.HeaderCity>
                {data.user.city.name}
            </styles.HeaderCity>
            <styles.SideBarButtonContainer>
                <SideBar/>
            </styles.SideBarButtonContainer>
        </styles.PageHeader>
    )

}

export default Header