import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

export const Container = styled.div`
  width: 100%;
`

export const DeliveryTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
`

export const TimeTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
`

export const TimeTypeContainer = styled.div``


export const CommentContainer = styled.div`
  width: 100%;
  padding-top: .5rem;
`

export const CommentText = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #6e6e6e;
`

export const CommentTextArea = styled(TextareaAutosize)`
  margin-top: .5rem;
  width: 100%;
  color: #5f5f5f;
  border: 1px solid #d6d6d6;
  padding: .5rem .5rem;
  outline: none;
`

export const PreOrderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
`

export const PreOrderTitle = styled.p`
  margin: 0;
  font-size: 1rem;
  color: black;
  font-weight: bold;
`

export const DatePickerContainer = styled.div`
  width: 100%;
  padding-top: .5rem;
`

export const PreOrderDescriptionContainer = styled.div`
  width: 100%;
  padding-top: .5rem;
`

export const PreorderDescription = styled.p`
  margin: 0;
  color: #c50000;
  font-size: 1rem;
`

export const TimeLoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`