import styled from "styled-components";

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const StepperTextContainer = styled.div``

export const StepperText = styled.span`
  font-size: 1rem;
`

export const StepsText = styled(StepperText)`
  font-weight: bold;
`

export const StepsContainer = styled.div`
  display: flex;
  gap: .5rem;
`

export const Step = styled.div<{ active?: boolean }>`
  width: 1rem;
  height: .2rem;
  border-radius: 5px;
  background-color: ${({active}) => active ? '#c50000' : '#ffffff'};
`