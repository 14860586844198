import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useYMaps} from "@pbe/react-yandex-maps";
import DataContext from "../../DataProvider";
import * as checkoutStyles from "../../../styles/checkout";
import * as styles from "../../../styles/checkout/delivery";
import {DeliveryAreaDTO} from "../../../../../api/dto/deliveryArea.dto";
import {ClipLoader} from "react-spinners";
import ymaps from "yandex-maps";
import {CheckoutForm} from "../../pages/CheckoutPage";
import NextStepButton from "../NextStepButton";
import Card from "../Card";

type FORM_ERRORS = "INVALID_ADDRESS" | "NO_DELIVERY"

const FormMessages: { [key in FORM_ERRORS]: string } = {
    INVALID_ADDRESS: "Неполный адрес, требуется уточнение: нужен номер дома.",
    NO_DELIVERY: "Ошибка! Адрес не входит в зону доставки. Вы можете заказать самовывоз."
}


const DeliveryForm: CheckoutForm = ({nextStepCallback}) => {
    const ymapsAPI = useYMaps([
        "SuggestView",
        "geocode",
        "geoQuery",
        "Polygon",
        "Map"
    ])


    const data = useContext(DataContext)

    const handleAddressInputChange = useCallback((value: string) => {
        if (value) {
            setAddressInput(value)
        } else {
            setAddressInput(data.user.city.name + ',')
        }
    }, [data.user.city.name])

    const [map, setMap] = useState<ymaps.Map>()
    const [zones, setZones] = useState<any>(data.checkoutFormsCache.deliveryZones)
    const [suggestViewInstance, setSuggestInstance] = useState(null)

    useEffect(() => {
        if (ymapsAPI && !suggestViewInstance && !map) {
            const api: any = ymapsAPI
            const suggestView = new api.SuggestView("suggest");
            suggestView.events.add(['select'], (item: any) =>
                handleAddressInputChange(item.originalEvent.item.value)
            )
            setSuggestInstance(suggestView)
            const map = new ymapsAPI.Map('map', {center: [45.95557464028013, 51.49824072382998], zoom: 12})
            setMap(map)
            if (!zones) {
                setAreasLoading(true)
                fetch(`${process.env.REACT_APP_API_URL}/deliveryArea?cityId=${data.user.city.id}`)
                    .then(res => res.json())
                    .then(json => {
                        const areas: DeliveryAreaDTO[] = json;
                        setDeliveryAreasData(
                            Object.fromEntries(areas.map(a => ([a.id, a])))
                        )
                        const zonesGeo = (ymapsAPI as any).geoQuery(areas.map(a =>
                            new ymapsAPI.Polygon(a.coordinates, {
                                id: a.id
                            })))
                        setZones(zonesGeo)
                        data.updateCheckoutCache({
                            ...data.checkoutFormsCache,
                            deliveryZones: zonesGeo,
                            deliveryAreas: areas
                        })
                        zonesGeo.addToMap(map)

                    })
                    .finally(() => setAreasLoading(false))
            } else {
                zones.addToMap(map)
                if (data.checkoutFormsCache.deliveryAreas) {
                    setDeliveryAreasData(
                        Object.fromEntries(data.checkoutFormsCache.deliveryAreas.map(a => ([a.id, a])))
                    )
                }
            }

        }
    }, [ymapsAPI, handleAddressInputChange, data, zones, map, suggestViewInstance])


    const [addressInput, setAddressInput] = useState<string>(data.checkoutData.deliveryAddress?.address || data.user.city.name + ',')
    const [addressHouse, setAddressHouse] = useState<number | null>(data.checkoutData.deliveryAddress?.house || null)
    const [applied, setApplied] = useState(data.checkoutData.deliveryCost !== undefined)
    const [applyError, setApplyError] = useState<FORM_ERRORS | null>(null)
    const [privateHouse, setPrivateHouse] = useState(data.checkoutData.deliveryAddress?.privateHouse || false)
    const [areasLoading, setAreasLoading] = useState(!data.checkoutFormsCache.deliveryZones)
    const [deliveryAreasData, setDeliveryAreasData] = useState<Record<number, DeliveryAreaDTO>>({})
    const [selectedArea, setSelectedArea] = useState<DeliveryAreaDTO | null>(data.checkoutFormsCache.selectedDeliveryZone || null)


    const handleAddressApply = useCallback(() => {
        data.updateCheckoutData({...data.checkoutData, deliveryCost: undefined})
        if (applied) {
            setApplied(false)
        } else {
            setApplyError(null)
            setApplied(false)
            setAreasLoading(true)
            setSelectedArea(null)
            if (ymapsAPI && map && zones) {
                (ymapsAPI.geocode(addressInput) as any).then((res: any) => {
                    const geoResult = res.geoObjects.get(0)
                    if (geoResult &&
                        geoResult.geometry &&
                        geoResult.properties
                            .get('metaDataProperty.GeocoderMetaData.precision', {}).toString() === 'exact') {
                        const cords = (geoResult.geometry as any).getCoordinates()
                        const res = zones.searchContaining(cords).get(0)
                        if (res) {
                            let zoneId = res.properties.get("id", null)
                            if (zoneId != null) {
                                const geoComponents: any[] =
                                    geoResult.properties.get
                                    ('metaDataProperty.GeocoderMetaData.Address.Components')
                                const house = geoComponents.find(c => c.kind === 'house')?.name
                                if (house) {
                                    const area = deliveryAreasData[Number(zoneId)]
                                    setSelectedArea(area)
                                    data.updateCheckoutData({...data.checkoutData, deliveryCost: area.deliveryCost})
                                    setAddressHouse(house)
                                    setApplied(true)
                                }

                            }
                        } else {
                            setApplyError("NO_DELIVERY")
                        }
                        setAreasLoading(false)

                    } else {
                        setAreasLoading(false)
                        setApplyError("INVALID_ADDRESS")
                    }
                })
            }
        }

    }, [addressInput, ymapsAPI, map, zones, deliveryAreasData, applied, data])

    const handlePrivateHouseChange = useCallback(() => {
        setPrivateHouse(!privateHouse)
    }, [privateHouse])


    const [entrance, setEntrance] = useState(data.checkoutData.deliveryAddress?.entrance || "")
    const [floor, setFloor] = useState(data.checkoutData.deliveryAddress?.floor || "")
    const [flat, setFlat] = useState(data.checkoutData.deliveryAddress?.flat || "")
    const [intercomNotWorks, setIntercomNotWorks] = useState(data.checkoutData.deliveryAddress?.intercomNotWorks || false)

    const handleIntercomNotWorksChange = useCallback(() => {
        setIntercomNotWorks(!intercomNotWorks)
    }, [intercomNotWorks])

    const addressCorrect = useMemo(() => applied && (privateHouse || (entrance && floor && flat)),
        [applied, privateHouse, entrance, floor, flat])

    const handleNext = useCallback(() => {
        if (selectedArea && addressHouse) {
            const previousPoint = data.checkoutData.pickupPoint
            const newData = {
                ...data.checkoutData,
                pickupPoint: selectedArea.pickupPointId,
                deliveryAddress: {
                    address: addressInput,
                    flat: flat,
                    floor: floor,
                    entrance: entrance,
                    privateHouse: privateHouse,
                    intercomNotWorks: intercomNotWorks,
                    house: addressHouse
                },
                deliveryCost: selectedArea.deliveryCost
            }
            data.updateCheckoutData(newData)
            const newCache = {
                ...data.checkoutFormsCache,
                selectedDeliveryZone: selectedArea
            }
            if (previousPoint !== selectedArea.pickupPointId) {
                newCache.preOrderSlots = undefined
            }
            data.updateCheckoutCache(newCache)
            nextStepCallback()
        }

    }, [data, selectedArea, privateHouse, entrance, floor, flat, addressHouse, addressInput, intercomNotWorks, nextStepCallback])

    return (
        <styles.Container>
            <Card title='Адрес'>
                <styles.DeliveryFormContainer>
                    <div id="map" style={{display: "none"}}/>
                    <NextStepButton
                        disabled={!addressCorrect}
                        onClick={handleNext}/>
                    <styles.AddressContainer>
                        <styles.AddressDescriptionContainer>
                            <styles.AddressDescription>
                                Адрес{' '}
                            </styles.AddressDescription>
                            <styles.AddressDescriptionBold>
                                (город, улица, номер дома)
                            </styles.AddressDescriptionBold>
                            <styles.RequiredMark>
                                *
                            </styles.RequiredMark>
                        </styles.AddressDescriptionContainer>
                        <styles.AddressInputContainer>
                            <styles.AddressInput type="text" id="suggest" value={addressInput}
                                                 onChange={e => handleAddressInputChange(e.target.value)}
                                                 disabled={applied}
                            />
                        </styles.AddressInputContainer>
                        <styles.AddressApplyButtonContainer>
                            <styles.AddressApplyButton
                                onClick={() => handleAddressApply()}
                                disabled={areasLoading}
                                type='button'
                            >
                                {areasLoading
                                    ?
                                    <styles.ButtonLoadingContainer>
                                        <ClipLoader size='1em' color='inherit'/>
                                    </styles.ButtonLoadingContainer>
                                    :
                                    applied ? 'изменить' : 'подтвердить адрес'
                                }
                            </styles.AddressApplyButton>
                        </styles.AddressApplyButtonContainer>
                        {(applied || applyError) &&
                            <styles.ApplyResultContainer>
                                <styles.ApplyResult $result={applied}>
                                    {applied
                                        ?
                                        "Спасибо, адрес подтвержден!"
                                        :
                                        applyError && FormMessages[applyError]
                                    }
                                </styles.ApplyResult>
                            </styles.ApplyResultContainer>}
                    </styles.AddressContainer>
                    <styles.PrivateHouseContainer>
                        <checkoutStyles.FormRadioLabel>
                            <checkoutStyles.FormCheckbox checked={privateHouse} onChange={handlePrivateHouseChange}/>
                            Частный дом/Нет подъезда, этажа и квартиры
                        </checkoutStyles.FormRadioLabel>
                    </styles.PrivateHouseContainer>
                    <styles.AddressDetailsContainer disabled={privateHouse}>
                        <styles.DetailContainer>
                            <styles.DetailLabelContainer>
                                <styles.DetailLabel>Подъезд</styles.DetailLabel>
                                <styles.RequiredMark>
                                    *
                                </styles.RequiredMark>
                            </styles.DetailLabelContainer>
                            <styles.DetailInput disabled={privateHouse} value={entrance}
                                                onChange={(e) => setEntrance(e.target.value)}/>
                        </styles.DetailContainer>
                        <styles.DetailContainer>
                            <styles.DetailLabelContainer>
                                <styles.DetailLabel>Этаж</styles.DetailLabel>
                                <styles.RequiredMark>
                                    *
                                </styles.RequiredMark>
                            </styles.DetailLabelContainer>
                            <styles.DetailInput disabled={privateHouse} value={floor}
                                                onChange={(e) => setFloor(e.target.value)}/>
                        </styles.DetailContainer>
                        <styles.DetailContainer>
                            <styles.DetailLabelContainer>
                                <styles.DetailLabel>Квартира</styles.DetailLabel>
                                <styles.RequiredMark>
                                    *
                                </styles.RequiredMark>
                            </styles.DetailLabelContainer>
                            <styles.DetailInput disabled={privateHouse} value={flat}
                                                onChange={(e) => setFlat(e.target.value)}/>
                        </styles.DetailContainer>
                    </styles.AddressDetailsContainer>
                    {!privateHouse &&
                        <styles.DoorPhoneContainer>
                            <checkoutStyles.FormRadioLabel>
                                <checkoutStyles.FormCheckbox checked={intercomNotWorks}
                                                             onChange={handleIntercomNotWorksChange}/>
                                Домофон не работает
                            </checkoutStyles.FormRadioLabel>
                        </styles.DoorPhoneContainer>}
                    {/*<input type="text" id="suggest" value={addressInput}*/}
                    {/*       onChange={e => handleAddressInputChange(e.target.value)}/>*/}
                    {/*<SuggestComponent/>*/}
                </styles.DeliveryFormContainer>
            </Card>
        </styles.Container>

    )
}

export default DeliveryForm