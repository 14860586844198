import styled from "styled-components";
import {ReactComponent as CloseIcon} from "../icons/close.svg"

export const CategoryContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CategoryTitle = styled.span`
  margin-top: .4rem;
  font-weight: 800;
  font-size: 1.5rem;
  text-align: center;
`

export const ProductsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: .8rem;
`

export const ProductContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 1.5rem .2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff;
`


export const ProductImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`

interface ImageProps {
    url: string
}

export const ProductImage = styled.img.attrs<ImageProps>(({url}) => ({
    src: url
}))<ImageProps>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 2px;
`


export const RecommendChip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: .2rem .4rem;
  font-size: .8rem;
  background-color: #1bcda1;
  border-radius: 13px;
  color: white;
`

export const ProductRatingContainer = styled.div`
  padding-top: .5rem;
  width: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: center;
`

export const ProductText = styled.span`
  margin-top: .4rem;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
`

export const ProductPriceContainer = styled.div`
  margin-top: .4rem;
  display: flex;
  justify-content: center;
  gap: .2rem;
`

export const NewPriceText = styled.span`
  color: red;
`

export const OldPriceText = styled.span`
  color: #9e9e9e;
  text-decoration-line: line-through;
`


export const SelectedModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  background-color: rgba(232, 232, 232, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SelectedProductContainer = styled.div`
  width: 90%;
  max-height: 80%;
  overflow-y: auto;
`

export const SelectedProductCard = styled.div`
  position: relative;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SelectedProductTitle = styled.span`
  font-weight: 600;
  font-size: 1rem;
`

export const SelectedImageContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: auto;
  position: relative;
`

export const SelectedDescription = styled.span`
  white-space: pre-line;
  margin-top: .5rem;
  width: 100%;
`

export const SelectedCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  fill: #9e9e9e;
  width: 1rem;
  height: 1rem;
`

export const ProductCartContainer = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
`
