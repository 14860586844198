import {CheckoutForm} from "../../pages/CheckoutPage";
import * as styles from "../../../styles/checkout/customer"
import Card from "../Card";
import NextStepButton from "../NextStepButton";
import React, {useCallback, useContext, useState} from "react";
import DataContext from "../../DataProvider";

const CustomerForm: CheckoutForm = ({nextStepCallback}) => {

    const data = useContext(DataContext)

    const [name, setName] = useState(data.user.name)

    const handleNext = useCallback(() => {
        if (name) {
            data.updateUserName(name)
            nextStepCallback()
        }
    }, [name, data, nextStepCallback])

    return (
        <styles.Container>
            <NextStepButton disabled={!name} onClick={handleNext}/>
            <Card title='Покупатель'>
                <styles.NameInputContainer>
                    <styles.NameLabelContainer>
                        <styles.NameLabel>
                            Имя
                        </styles.NameLabel>
                        <styles.RequiredMark>
                            *
                        </styles.RequiredMark>
                    </styles.NameLabelContainer>
                    <styles.NameInput value={name} onChange={e => setName(e.target.value)}/>
                </styles.NameInputContainer>
            </Card>
        </styles.Container>
    )
}

export default CustomerForm