import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
`

export const LegalText = styled.span`
  font-size: 0.7rem;
  white-space: nowrap;
`

export const DocumentsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  gap: .4rem;
`

export const DocumentLink = styled.a`
  font-size: 0.7rem;
  text-decoration: none;
  color: black;
  white-space: nowrap;
`