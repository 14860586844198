import styled from "styled-components";
import {ReactComponent as CartIcon} from "../icons/basket.svg";
import {ReactComponent as MinusIcon} from "../icons/minus.svg";
import {ReactComponent as PlusIcon} from "../icons/plus.svg";

export const CartButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
`


export const CartButton = styled.button`
  font-size: 1em;
  padding: .5em .75em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background-color: #c50000;
  outline: none;
  border: none;
`

export const ButtonText = styled.span`
  margin-left: .25em;
  color: white;
  font-size: 1em;
  white-space: nowrap;
`

export const ButtonIcon = styled(CartIcon)`
  width: 1em;
  height: 1em;
  fill: white;
`


export const RemoveIcon = styled(MinusIcon)`
  width: 1.5em;
  height: 1.5em;
  fill: #c50000;


`
export const AddIcon = styled(PlusIcon)`
  width: 1.5em;
  height: 1.5em;
  fill: #c50000;
`


export const CountText = styled.span`
  font-size: 1.5em;;
  margin-left: .25em;
  margin-right: .25em;
  color: #282c34;
`

export const AddLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  color: white;
`


export const UpdateLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
