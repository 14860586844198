import styled from "styled-components";


export const Container = styled.form`
  width: 100%;
`

export const PickupPointsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .1rem;
`

export const PickupPointContainer = styled.div`
  padding: 1rem .5rem;
  border: 1px solid #e6e7e9;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: .5rem;
`

export const PickupPointHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const PickupPointAddress = styled.span`
  font-size: 1rem;
`

export const PickupPointFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
`

export const FooterText = styled.span`
  font-size: 1rem;
`

export const PickupPointWaitingTime = styled(FooterText)`
  font-weight: bold;
  white-space: nowrap;
`