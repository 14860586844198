import React from "react";
import * as styles from "../styles/rating"

const Rating: React.FC = () => {

    return (
        <styles.Container>
            {[...Array(5)].map((_, i) => <styles.Star key={i}/>)}
        </styles.Container>
    )
}

export default Rating