import styled from "styled-components";

export const MenuContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MenuSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

export const MenuTitle = styled.span`
  margin-top: .4rem;
  font-weight: 800;
  font-size: 1.5rem;
  text-align: left;
  padding: 0 .5rem;
`

export const MenuItemsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  //display: grid;
  //align-items: start;
  //justify-items: start;
  //padding: 1rem;
  //grid-template-columns: 1fr 1fr;
  //grid-auto-rows: max-content;
  //row-gap: 1rem;
  //column-gap: .2rem;
`

export const PromoItemsContainer = styled(MenuItemsContainer)`
  gap: 1rem;
  align-items: center;
  padding-bottom: 1rem;
`

export const PromoItemContainer = styled.div`
  width: 90%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 0 46px rgba(228, 225, 212, .3);
  border-radius: 10px;
`


interface ImageProps {
    url: string
}

export const PromoItemImage = styled.img.attrs<ImageProps>(({url}) => ({src: url}))<ImageProps>`
  width: 100%;
  height: auto;
  object-fit: contain;
`

export const PromoItemNameContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
`

export const PromoItemName = styled.p`
  margin: 0;
  color: #4c1e09;
`

export const PromoItemFooterContainer = styled.div`
  width: 100%;
  padding: 1rem;
  border-top: 1px solid #dbd2ce;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
`

export const PromoItemPriceContainer = styled.div`
  display: flex;
  flex: 0 auto;
  flex-wrap: wrap;
  gap: .2rem;
  font-size: 1rem;
`

export const PromoItemPrice = styled.p`
  white-space: nowrap;
  margin: 0;
  color: #ff1e00;
`

export const PromoItemOldPrice = styled(PromoItemPrice)`
  text-decoration: line-through;
  color: #b2a7a2;
`

export const MenuItemContainer = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #f2f2f2;
  padding: 1rem .5rem;
  //justify-content: center;
  gap: .5rem;
  align-items: center;
  //border-radius: 2px;
  //border: 1px solid #f2f2f2;
`

export const PromoShowMoreButton = styled.button`
  outline: none;
  font-size: 1rem;
  background-color: transparent;
  color: #c50000;
  border: 1px solid #c50000;
  padding: .5rem 1rem;
  border-radius: 3px;
`

export const PromoCartContainer = styled.div`
  font-size: 1rem;
  flex: initial;
`


export const MenuItemImage = styled.img.attrs<ImageProps>(({url}) => ({
    src: url
}))<ImageProps>`
  width: 15%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
`

export const MenuItemText = styled.span`
  margin-top: .6rem;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
`