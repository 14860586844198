import {CheckoutForm} from "../../pages/CheckoutPage";
import * as styles from "../../../styles/checkout/payment"
import * as checkoutStyles from "../../../styles/checkout"
import Card from "../Card";
import {useCallback, useContext, useState} from "react";
import DataContext from "../../DataProvider";
import {PaymentType} from "../../../../../api/dto/checkout.dto";
import NextStepButton from "../NextStepButton";

const PaymentForm: CheckoutForm = ({nextStepCallback}) => {

    const data = useContext(DataContext)
    const [selectedType, setSelectedType] = useState<PaymentType | null>()
    const [cashChange, setCashChange] = useState(false)
    const [cashChangeBill, setCashChangeBill] = useState(0)
    const [bonuses, setBonuses] = useState(0)
    const [orderProcessing, setOrderProcessing] = useState(false)

    const handleTypeChange = useCallback((newType: PaymentType) => {
        setSelectedType(newType)
    }, [])

    const handleCashChange = useCallback(() => {
        setCashChange(!cashChange)
    }, [cashChange])

    const handleNext = useCallback(() => {
        if (selectedType) {
            setOrderProcessing(true)
            const newData = {
                ...data.checkoutData,
                paymentType: selectedType,
                cashChange: cashChange,
                cashChangeBill: cashChangeBill,
                bonuses: bonuses
            }
            data.updateCheckoutData(newData)
            nextStepCallback(newData)
        }
    }, [nextStepCallback, data, selectedType, cashChange, cashChangeBill, bonuses])

    const handleBonusesChange = useCallback((v: number) => {
        const value = v > data.cartData.bonusesSpend ? data.cartData.bonusesSpend : v
        setBonuses(value)
    }, [data.cartData.bonusesSpend])

    return (
        <styles.Container>
            <NextStepButton disabled={!selectedType || orderProcessing || !data.user.city.isWork}
                            loading={orderProcessing}
                            onClick={() => handleNext()}
                            text={'Оформить заказ'}
            />
            {data.user.rolikUser && data.cartData.bonusesAvailable &&
                <Card title='Бонусов списать'>
                    <styles.BonusesContainer>
                        <styles.BonusesText>
                            На вашем счету -{" "}
                            <styles.BonusesCount>
                                {data.user.rolikUser.bonuses}
                            </styles.BonusesCount>
                            {" "} баллов.
                        </styles.BonusesText>
                        <styles.BonusesText>
                            На данный заказ максимальное списание возможно на:{" "}
                            <styles.BonusesCount>
                                {data.cartData.bonusesSpend}.
                            </styles.BonusesCount>
                        </styles.BonusesText>
                        <styles.BonusesText>
                            За заказ будет начислено баллов:{" "}
                            <styles.BonusesCount>
                                {data.cartData.bonusesReceive}.
                            </styles.BonusesCount>
                        </styles.BonusesText>
                        <styles.CashChangeInput type={'number'}
                                                step="1"
                                                value={bonuses}
                                                onChange={e => handleBonusesChange(e.target.valueAsNumber)}/>
                    </styles.BonusesContainer>
                </Card>
            }
            <Card title='Оплата'>
                <styles.PaymentTypesContainer>
                    <styles.PaymentTypeContainer>
                        <checkoutStyles.FormRadioLabel>
                            <checkoutStyles.FormRadioBox
                                checked={selectedType === 'CARD'}
                                onChange={(e) => e.target.value && handleTypeChange('CARD')}
                            />
                            Картой
                        </checkoutStyles.FormRadioLabel>
                    </styles.PaymentTypeContainer>
                    <styles.PaymentTypeContainer>
                        <checkoutStyles.FormRadioLabel>
                            <checkoutStyles.FormRadioBox
                                checked={selectedType === 'CASH'}
                                onChange={(e) => e.target.value && handleTypeChange('CASH')}
                            />
                            Наличными
                        </checkoutStyles.FormRadioLabel>
                        {selectedType === 'CASH' &&
                            <styles.CashChangeContainer>
                                <styles.CashChangeTitle onClick={handleCashChange}>
                                    {cashChange
                                        ?
                                        'Не нужна сдача'
                                        :
                                        'Нужна сдача с купюры'
                                    }
                                </styles.CashChangeTitle>
                                {cashChange &&
                                    <styles.CashChangeInputContainer>
                                        <styles.CashChangeInputLabel>
                                            Сдача с купюры (номинал)
                                        </styles.CashChangeInputLabel>
                                        <styles.CashChangeInput type='number' value={cashChangeBill}
                                                                onChange={e => setCashChangeBill(e.target.valueAsNumber)}/>
                                    </styles.CashChangeInputContainer>
                                }
                            </styles.CashChangeContainer>
                        }
                    </styles.PaymentTypeContainer>
                </styles.PaymentTypesContainer>
                {selectedType &&
                    <styles.DescriptionContainer>
                        <styles.DescriptionMain>
                            Оплата производится наличными деньгами или банковской картой в момент получения заказа.
                            Подтверждением вашей оплаты является фискальный кассовый чек, вручаемый во время получения и
                            оплаты заказа
                        </styles.DescriptionMain>
                        <styles.DescriptionAdditional>
                            Нажимая на кнопку "оформить заказ", вы соглашаетесь на условия использования данного сервиса
                            и подтверждаете, что ознакомились с {" "}
                            <styles.DescriptionLink target='_blank'
                                                    href={`https://${data.user.city.domain}.roliksushi.ru/privacy-policy`}>
                                политикой конфиденциальности
                            </styles.DescriptionLink>
                            .
                        </styles.DescriptionAdditional>
                    </styles.DescriptionContainer>
                }

            </Card>
        </styles.Container>
    )
}

export default PaymentForm