import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const PaymentTypesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
`

export const PaymentTypeContainer = styled.div``

export const CashChangeContainer = styled.div`
  width: 100%;
  padding-top: 1.5rem;
`

export const CashChangeTitle = styled.span`
  color: #6e6e6e;
  text-decoration: underline;
  font-size: 1rem;
`

export const CashChangeInputContainer = styled.div`
  padding-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .2rem;
`

export const CashChangeInputLabel = styled.p`
  margin-top: 0;
  font-size: .8rem;
  color: #6e6e6e;
`

export const CashChangeInput = styled.input`
  width: 100%;
  color: #5f5f5f;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  padding: .4rem .5rem;
  outline: none;
`

export const DescriptionContainer = styled.div`
  width: 100%;
  font-size: 1rem;
  padding-top: .6rem;
  display: flex;
  flex-direction: column;
  gap: .2rem;
`

export const DescriptionMain = styled.p`
  margin: 0;
  color: #6d6d6d;
`

export const DescriptionAdditional = styled.span`
  opacity: 0.5;
`

export const DescriptionLink = styled.a`

`

export const BonusesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
`

export const BonusesText = styled.p`
  margin: 0;
`

export const BonusesCount = styled.span`
  font-weight: bold;
`
