import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {DataProvider} from './components/DataProvider';
import MenuPage from "./components/pages/MenuPage";
import GlobalStyles, {PageContainer, PageContent} from './styles';
import CategoryPage from "./components/pages/CategoryPage";
import CartPage from './components/pages/CartPage';
import Cart from "./components/Cart";
import ScrollToTop from "./components/ScrollToTop";
import {YMaps} from '@pbe/react-yandex-maps';
import CheckoutPage from "./components/pages/CheckoutPage";
import Header from "./components/pages/Header";
import LegalInfo from "./components/LegalInfo";


const App = () => {

    const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null)
    const tg = useRef((window as any).Telegram.WebApp)
    if (!tg.current.isExpanded) {
        tg.current.expand()
    }

    useEffect(() => {
        (window as any).ym(93650254, 'reachGoal', 'storeOpen')
    }, [])

    return (
        <>
            <GlobalStyles/>
            <YMaps query={{
                apikey: process.env.REACT_APP_YANDEX_MAPS_API_KEY,
                lang: 'ru_RU',
                coordorder: 'longlat'
            }}>

                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <DataProvider>
                        {contentRef && <ScrollToTop el={contentRef}/>}
                        <PageContainer>
                            <Header/>
                            <PageContent ref={(ref) => setContentRef(ref)}>
                                <Routes>
                                    <Route path={"/:id?"} element={<MenuPage/>}/>
                                    <Route path={"/menu/:id"} element={<CategoryPage/>}/>
                                    <Route path={"/cart"} element={<CartPage/>}/>
                                    <Route path={"/checkout"} element={<CheckoutPage/>}/>
                                </Routes>
                                <Routes>
                                    <Route path={"/cart"} element={<></>}/>
                                    <Route path={"/checkout"} element={<></>}/>
                                    <Route path={"*"} element={<Cart/>}/>
                                </Routes>
                                <LegalInfo/>
                            </PageContent>

                        </PageContainer>
                    </DataProvider>
                </BrowserRouter>
                {/*<Cart/>*/}
            </YMaps>
        </>

    )
}

export default App;