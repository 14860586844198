import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import * as styles from "../../styles/category"
import {useNavigate, useParams} from "react-router-dom";
import DataContext from "../DataProvider";
import {Product} from "../../../../api/dto/product.dto";
import {ClipLoader} from "react-spinners";
import {BackButton} from "@vkruglikov/react-telegram-web-app";
import useDeepCompareEffect from "use-deep-compare-effect";
import CartItemButton from "../CartItemButton";
import Rating from "../Rating";
import SelectedProduct from "../SelectedProduct";

const CategoryPage: React.FC = () => {

    const navigate = useNavigate();
    const data = useContext(DataContext)
    const {id} = useParams()

    const menu = useMemo(() => {
        return data.menusData[Number(id)]
    }, [id, data.menusData])


    const [products, setProducts] = useState<Product[]>([])
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
    }, [menu?.id])

    useEffect(() => {
        if (menu) {
            data.getProducts(menu).then(res => setProducts(res))
            // .then(_ => setLoading(false))
        }
    }, [menu, data])

    useDeepCompareEffect(() => {
        if (products.length) {
            Promise.all([products.map(p => p.image).map(src => {
                return new Promise((resolve, reject) => {
                    const loadImg = new Image()
                    loadImg.src = src
                    loadImg.onload = (_ => resolve(src))
                    loadImg.onerror = (_ => reject(src))
                })
            })]).then(_ => setLoading(false))
        }
    }, [products])

    const handleBack = useCallback(() => {
        navigate(`/${menu?.parentId || ''}`)
    }, [navigate, menu])

    const handleSelectProduct = useCallback((product: Product) => {
        setSelectedProduct(product)
    }, [])

    return (
        <>
            <BackButton onClick={handleBack}/>
            {selectedProduct &&
                <SelectedProduct item={selectedProduct} onClose={() => setSelectedProduct(null)} computeSummary/>}
            {menu &&
                <styles.CategoryContainer>
                    <styles.CategoryTitle>
                        {menu.title}
                    </styles.CategoryTitle>
                    {loading
                        ?
                        <ClipLoader cssOverride={{marginTop: 'auto', marginBottom: 'auto'}}/>
                        :
                        <styles.ProductsContainer>
                            {products.map(p =>
                                <styles.ProductContainer key={p.id}>
                                    <styles.ProductImageContainer onClick={() => handleSelectProduct(p)}>
                                        <styles.ProductImage url={p.image}/>
                                        {p.recommended &&
                                            <styles.RecommendChip>
                                                Рекоммендуем
                                            </styles.RecommendChip>}
                                    </styles.ProductImageContainer>
                                    <styles.ProductRatingContainer>
                                        <Rating/>
                                    </styles.ProductRatingContainer>

                                    <styles.ProductText>{p.title}</styles.ProductText>
                                    <styles.ProductPriceContainer>
                                        <styles.NewPriceText>
                                            {Math.trunc(p.newPrice)} ₽
                                        </styles.NewPriceText>
                                        {p.newPrice !== p.oldPrice &&
                                            <styles.OldPriceText>
                                                {Math.trunc(p.oldPrice)} ₽
                                            </styles.OldPriceText>}
                                    </styles.ProductPriceContainer>
                                    <styles.ProductCartContainer>
                                        <CartItemButton item={data.cartItemsData[p.id] || p} computeSummary/>
                                    </styles.ProductCartContainer>
                                </styles.ProductContainer>)}
                        </styles.ProductsContainer>
                    }
                </styles.CategoryContainer>
            }
        </>
    )
}

export default CategoryPage