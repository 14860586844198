import React, {useCallback, useContext, useMemo, useState} from "react";
import * as checkoutStyles from "../../../styles/checkout/index"
import * as styles from "../../../styles/checkout/receiving"
import {ReceivingType} from "../../../../../api/dto/checkout.dto";
import DataContext from "../../DataProvider";
import {CheckoutForm} from "../../pages/CheckoutPage";
import NextStepButton from "../NextStepButton";
import Card from "../Card";

const ReceivingTypeForm: CheckoutForm = ({nextStepCallback}) => {

    const data = useContext(DataContext)
    const [selectedType, setSelectedType] = useState<ReceivingType | null>(
        data.checkoutData.receivingType ||
        (data.cartData.deliveryAvailable && 'DELIVERY') ||
        (data.cartData.pickupAvailable && 'PICKUP')
        || null
    )

    const handleTypeChange = useCallback((value: ReceivingType) => {
        setSelectedType(value)
    }, [])

    const handleNext = useCallback(() => {
        if (selectedType) {
            data.updateCheckoutData({...data.checkoutData, receivingType: selectedType})
            nextStepCallback()
        }

    }, [selectedType, data, nextStepCallback])

    const deliveryDescription = useMemo(() => {
        if (!data.cartData.deliveryAvailable) {
            return data.cartData.couponValid
                ?
                'Для использованного промокода отстутствует возможность доставки'
                :
                'Минимальная сумма для доставки - 599₽'
        }
        return 'Расчет стоимости доставки'
    }, [data.cartData.deliveryAvailable, data.cartData.couponValid])

    return (
        <styles.Container>
            <NextStepButton disabled={!selectedType} onClick={handleNext}/>
            <Card title='Получение'>
                <styles.ReceivingContainer>
                    <styles.ReceivingTypeContainer>
                        <styles.ReceivingTypeCheckboxContainer>
                            <checkoutStyles.FormRadioLabel disabled={!data.cartData.deliveryAvailable}>
                                <checkoutStyles.FormRadioBox
                                    disabled={!data.cartData.deliveryAvailable}
                                    checked={selectedType === 'DELIVERY'}
                                    onChange={(e) => e.target.value && handleTypeChange("DELIVERY")}
                                />
                                Доставка
                            </checkoutStyles.FormRadioLabel>
                        </styles.ReceivingTypeCheckboxContainer>
                        {(selectedType === 'DELIVERY' || !data.cartData.deliveryAvailable) &&
                            <checkoutStyles.RadioBoxDescription disabled={!data.cartData.deliveryAvailable}>
                                {deliveryDescription}
                            </checkoutStyles.RadioBoxDescription>}
                    </styles.ReceivingTypeContainer>

                    {data.cartData.pickupAvailable &&
                        <styles.ReceivingTypeContainer>
                            <styles.ReceivingTypeCheckboxContainer>
                                <checkoutStyles.FormRadioLabel>
                                    <checkoutStyles.FormRadioBox
                                        checked={selectedType === 'PICKUP'}
                                        onChange={(e) => e.target.value && handleTypeChange("PICKUP")}
                                    />
                                    Самовывоз
                                </checkoutStyles.FormRadioLabel>
                            </styles.ReceivingTypeCheckboxContainer>
                            {selectedType === 'PICKUP' && <checkoutStyles.RadioBoxDescription>
                                Вы можете забрать заказ в одной из доступных точек
                            </checkoutStyles.RadioBoxDescription>}
                        </styles.ReceivingTypeContainer>
                    }
                </styles.ReceivingContainer>
            </Card>

        </styles.Container>
    )
}

export default ReceivingTypeForm