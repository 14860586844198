import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  background: #fff;
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  justify-content: start;
`

export const Header = styled.h1`
  margin: 0;
  font-size: 1.5rem;
  font-weight: normal;
`

export const Content = styled.div`
  width: 100%;
`