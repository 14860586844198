import styled, {createGlobalStyle} from "styled-components";
import {ReactComponent as Logo} from "../styles/icons/logo.svg"

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Manrope', sans-serif;
  }

  *::-webkit-scrollbar {
    width: 0 !important;
  }

  html {
    font-size: 18px;
  }
`

export const PageContainer = styled.div`
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: .5rem;
`

export const PageHeader = styled.div`
  width: 100%;
  padding: .7rem;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  background: #fff;
`

export const HeaderLogo = styled(Logo)`
  width: 35vw;
`

export const HeaderCity = styled.p`
  margin: 0;
  font-size: 1rem;
`

export const SideBarButtonContainer = styled.div`
  margin-left: auto;
`

export const PageContent = styled.div`
  overflow-x: hidden;
  background: #f9f8f4;
  flex: 1;
  width: 100%;
  min-height: 0;
`

export const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`

export default GlobalStyles