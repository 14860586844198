import styled from "styled-components";

// export const CheckoutContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

export const Container = styled.div`
  width: 100vw;
  max-width: 100vw;
  min-height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
`

export const CheckoutCard = styled.div`
  width: 100%;
  padding: 1rem;
  background: #fff;
`


export const CheckoutTitle = styled.span`
  color: black;
  font-size: 2rem;
`

export const FormTitle = styled.span`
  font-size: 1.5rem;
`

const FormBox = styled.input`
  height: 1rem;
  width: 1rem;
  border: 1px solid #ccc;
  margin: 0;

  &:checked {
    accent-color: #c50000;
  }

  &:hover {
    accent-color: #c50000;
  }

  &:focus {
    accent-color: #c50000;
  }
`

export const FormRadioBox = styled(FormBox).attrs(() => ({type: 'radio'}))``


export const FormCheckbox = styled(FormBox).attrs(() => ({type: 'checkbox'}))``


export const RadioBoxDescription = styled.p<{ disabled?: boolean }>`
  margin: 0;
  font-size: 1rem;
  color: ${({disabled}) => disabled ? '#c50000' : '#6d6d6d'};
`

export const FormRadioLabel = styled.label<{ disabled?: boolean }>`
  margin: 0;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  gap: .5rem;
  ${({disabled}) => disabled && ({color: '#ccc'})}
`


export const LoadingContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckoutCostContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const CartCostContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const CheckoutCostLabel = styled.span`
  font-size: 1rem;
`

export const CostContainer = styled.div`
  display: flex;
  gap: .2rem;
`

export const Cost = styled.span`
  font-size: 1rem;
`

export const OldCost = styled(Cost)`
  text-decoration: line-through;
  opacity: 0.5;
`


export const DeliveryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const DeliveryCost = styled.span<{ cost?: number }>`
  font-size: 1rem;
  color: ${({cost}) => cost === undefined ? 'rgb(220, 53, 69)' : cost === 0 ? '#257210' : 'black'};
`

export const CheckoutCostSummaryContainer = styled.div`
  width: 100%;
  border-top: #f2f2f2 1px solid;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
`

export const CheckoutCostSummary = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
`