import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`

export const NameInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
`

export const NameLabelContainer = styled.div`
  display: flex;
`

export const NameLabel = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #6e6e6e;
`

export const RequiredMark = styled(NameLabel)`
  color: #dc3545;
`

export const NameInput = styled.input`
  width: 100%;
  color: #5f5f5f;
  border: 1px solid #d6d6d6;
  padding: .4rem .5rem;
`